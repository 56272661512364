import { useCsrfToken, useRegister } from "client/actions/users";
import FallbackComponent from "client/FallbackComponent";
import { useDateFormatter } from "client/hooks/useDateFormatter";
import { RegisterRequest } from "client/types";
import scrollToTop from "client/utils/scrollToTop";
import React, { useContext, useEffect, useState } from "react";
import ArrowLeft from "../../../../../../assets/ui-icons/ArrowLeft";
import DropzoneThumbnailsNew from "../../../../../../components/DropzoneThumbnailsNew";
import { config } from "../../../../../../frontendConfig";
import { isMdQuery } from "../../../../../../helpers/mediaQuery";
import { colors, fontSizes, fontWeights } from "../../../../../../theme/uiTheme";
import { Button, Heading, Link, Text } from "../../../../../../ui-components";
import { LostItemCreationContext, useAuthState } from "./../../../../../../context";
import { reviewClaimCopy } from "./content";
import {
  Content,
  ErrorMessage,
  ReviewFieldHeader,
  StyledReCAPTCHA,
  StyledReCAPTCHAContainer,
  VerificationHeading,
  VerificationText,
} from "./styles";
export default function ReviewClaim() {
  const isMd = isMdQuery();

  const dateFormatter = useDateFormatter();
  const { user } = useAuthState();
  const {
    setStep,
    itemName,
    dateLost,
    category,
    firstName,
    lastName,
    email,
    phoneNumber,
    images,
    description,
    submitClaim,
  } = useContext(LostItemCreationContext);

  const [reCaptchaResponseValue, setReCaptchaResponseValue] = useState<string>("");
  const [reCaptchaError, setReCaptchaError] = useState<boolean>(false);
  const [setCsrf] = useCsrfToken();
  const [sendRegister, { data: registeredUser, loading: registerLoading }] = useRegister();
  const [isSendCodeButtonDisabled, setSendCodeButtonDisabled] = useState<boolean>(false);

  function onReCaptchaResponse(reCaptchaResponse) {
    setReCaptchaResponseValue(reCaptchaResponse as string);
    setReCaptchaError(false);
  }

  scrollToTop();

  useEffect(() => {
    if (registerLoading || registeredUser === null) return;
    setStep("otp");
  }, [registerLoading, registeredUser]);

  if (config.recaptchaSiteKey) {
    return (
      <Content>
        <Text
          className="mb-1"
          color={colors.primary500}
          lineHeight={1.5}
          fontWeight={fontWeights.bold}
          textAlign={isMd ? "center" : "left"}
        >
          {reviewClaimCopy.subheading}
        </Text>
        <Heading variant="h2" className="mb-3" textAlign={isMd ? "center" : "left"}>
          {reviewClaimCopy.heading}
        </Heading>
        <div className="text-md-center">
          <Text
            as="span"
            fontSize={{ base: fontSizes.base }}
            fontWeight={fontWeights.normal}
            lineHeight={1.5}
            textAlign={isMd ? "center" : "left"}
          >
            {reviewClaimCopy.supportingText}
          </Text>
        </div>
        {/* Claims detail section */}
        <Text
          as="div"
          fontSize={{ base: fontSizes.lg }}
          fontWeight={fontWeights.bold}
          textAlign={"left"}
          className="mt-35"
        >
          {reviewClaimCopy.claimDetailsSectionLabel}
        </Text>
        {/* Item name */}
        <div>
          <ReviewFieldHeader data-testid="itemNameField">
            <Text
              as="div"
              fontSize={{ base: fontSizes.base }}
              fontWeight={fontWeights.bold}
              lineHeight={1.5}
              textAlign="left"
            >
              {reviewClaimCopy.itemNameLabel}
            </Text>
            <Link
              onClick={event => {
                event.preventDefault();
                setStep("nameUpdate");
              }}
              data-testid="editName"
            >
              {reviewClaimCopy.editButton}
            </Link>
          </ReviewFieldHeader>
          <Text
            as="span"
            fontSize={{ base: fontSizes.base }}
            fontWeight={fontWeights.normal}
            lineHeight={1.5}
            color={colors.gray600}
            textAlign="left"
          >
            {itemName}
          </Text>
        </div>
        {/* Date lost */}
        <div>
          <ReviewFieldHeader data-testid="dateLostField">
            <Text
              as="div"
              fontSize={{ base: fontSizes.base }}
              fontWeight={fontWeights.bold}
              lineHeight={1.5}
              textAlign="left"
            >
              {reviewClaimCopy.dateLostLabel}
            </Text>
            <Link
              onClick={event => {
                event.preventDefault();
                setStep("dateUpdate");
              }}
              data-testid="editDate"
            >
              {reviewClaimCopy.editButton}
            </Link>
          </ReviewFieldHeader>
          <Text
            as="span"
            fontSize={{ base: fontSizes.base }}
            fontWeight={fontWeights.normal}
            lineHeight={1.5}
            color={colors.gray600}
            textAlign="left"
          >
            {dateFormatter(dateLost, "long")}
          </Text>
        </div>{" "}
        {/* Category */}
        <div>
          <ReviewFieldHeader data-testid="categoryField">
            <Text
              as="div"
              fontSize={{ base: fontSizes.base }}
              fontWeight={fontWeights.bold}
              lineHeight={1.5}
              textAlign="left"
            >
              {reviewClaimCopy.itemCategoryLabel}
            </Text>
            <Link
              onClick={event => {
                event.preventDefault();
                setStep("categoryUpdate");
              }}
              data-testid="editCategory"
            >
              {reviewClaimCopy.editButton}
            </Link>
          </ReviewFieldHeader>
          <Text
            as="span"
            fontSize={{ base: fontSizes.base }}
            fontWeight={fontWeights.normal}
            lineHeight={1.5}
            color={colors.gray600}
            textAlign="left"
          >
            {category[1]}
          </Text>
        </div>
        {/* Description */}
        <div>
          <ReviewFieldHeader data-testid="itemDescriptionField">
            <Text
              as="div"
              fontSize={{ base: fontSizes.base }}
              fontWeight={fontWeights.bold}
              lineHeight={1.5}
              textAlign="left"
            >
              {reviewClaimCopy.itemDescriptionLabel}
            </Text>
            <Link
              onClick={event => {
                event.preventDefault();
                setStep("descriptionUpdate");
              }}
              data-testid="editDescription"
            >
              {reviewClaimCopy.editButton}
            </Link>
          </ReviewFieldHeader>
          <Text
            as="span"
            fontSize={{ base: fontSizes.base }}
            fontWeight={fontWeights.normal}
            lineHeight={1.5}
            color={colors.gray600}
            textAlign="left"
          >
            {description}
          </Text>
        </div>{" "}
        {/* Photos */}
        <div>
          <ReviewFieldHeader data-testid="itemPhotosField">
            <Text
              as="div"
              fontSize={{ base: fontSizes.base }}
              fontWeight={fontWeights.bold}
              lineHeight={1.5}
              textAlign="left"
            >
              {reviewClaimCopy.itemPhotosLabel}
            </Text>
            <Link
              onClick={event => {
                event.preventDefault();
                setStep("photosUpdate");
              }}
              data-testid="editPhotos"
            >
              {reviewClaimCopy.editButton}
            </Link>
          </ReviewFieldHeader>
          <DropzoneThumbnailsNew
            canDeleteImages={false}
            deleteImage={() => undefined}
            images={images}
          />
        </div>
        {/* Contact details section */}
        {!user.id && (
          <>
            <ReviewFieldHeader data-testid="contactDetailsField">
              <Text
                as="div"
                fontSize={{ base: fontSizes.lg }}
                fontWeight={fontWeights.bold}
                textAlign={"left"}
                className="mt-35"
              >
                {reviewClaimCopy.contactDetailsSectionLabel}
              </Text>
              <Link
                onClick={event => {
                  event.preventDefault();
                  setStep("contactUpdate");
                }}
                data-testid="editContactDetails"
              >
                {reviewClaimCopy.editButton}
              </Link>
            </ReviewFieldHeader>
            {/* First name */}
            <div>
              <ReviewFieldHeader>
                <Text
                  as="div"
                  fontSize={{ base: fontSizes.base }}
                  fontWeight={fontWeights.bold}
                  lineHeight={1.5}
                  textAlign="left"
                >
                  {reviewClaimCopy.contactFirstNameLabel}
                </Text>
              </ReviewFieldHeader>
              <Text
                as="span"
                fontSize={{ base: fontSizes.base }}
                fontWeight={fontWeights.normal}
                lineHeight={1.5}
                color={colors.gray600}
                textAlign="left"
              >
                {firstName}
              </Text>
            </div>
            {/* Last name */}
            <div>
              <ReviewFieldHeader>
                <Text
                  as="div"
                  fontSize={{ base: fontSizes.base }}
                  fontWeight={fontWeights.bold}
                  lineHeight={1.5}
                  textAlign="left"
                >
                  {reviewClaimCopy.contactLastNameLabel}
                </Text>
              </ReviewFieldHeader>
              <Text
                as="span"
                fontSize={{ base: fontSizes.base }}
                fontWeight={fontWeights.normal}
                lineHeight={1.5}
                color={colors.gray600}
                textAlign="left"
              >
                {lastName}
              </Text>
            </div>
            {/* Email */}
            <div>
              <ReviewFieldHeader>
                <Text
                  as="div"
                  fontSize={{ base: fontSizes.base }}
                  fontWeight={fontWeights.bold}
                  lineHeight={1.5}
                  textAlign="left"
                >
                  {reviewClaimCopy.contactEmailLabel}
                </Text>
              </ReviewFieldHeader>
              <Text
                as="span"
                fontSize={{ base: fontSizes.base }}
                fontWeight={fontWeights.normal}
                lineHeight={1.5}
                color={colors.gray600}
                textAlign="left"
              >
                {email}
              </Text>
            </div>
            <div>
              <ReviewFieldHeader>
                <Text
                  as="div"
                  fontSize={{ base: fontSizes.base }}
                  fontWeight={fontWeights.bold}
                  lineHeight={1.5}
                  textAlign="left"
                >
                  {reviewClaimCopy.contactPhoneLabel}
                </Text>
              </ReviewFieldHeader>
              <Text
                as="span"
                fontSize={{ base: fontSizes.base }}
                fontWeight={fontWeights.normal}
                lineHeight={1.5}
                color={colors.gray600}
                textAlign="left"
              >
                {phoneNumber}
              </Text>
            </div>
          </>
        )}
        {/* ReCaptcha Section */}
        {!user.id && (
          <div>
            <VerificationHeading
              as="div"
              fontSize={{ base: fontSizes.lg }}
              fontWeight={fontWeights.bold}
              textAlign={"left"}
              className="mt-40"
            >
              {reviewClaimCopy.verificationEmailHeading}
            </VerificationHeading>
            <VerificationText
              as="span"
              fontSize={{ base: fontSizes.base }}
              fontWeight={fontWeights.normal}
              lineHeight={1.5}
              color={colors.gray600}
              textAlign="left"
              className="mt-20"
              data-testid="verificationText"
            >
              We will send a four digit code to{" "}
              <Text
                as="span"
                fontSize={{ base: fontSizes.base }}
                fontWeight={fontWeights.bold}
                lineHeight={1.5}
                color={colors.primary500}
              >
                {email}
              </Text>{" "}
              so we can verify your email.
            </VerificationText>
            <StyledReCAPTCHAContainer className="mt-4">
              <StyledReCAPTCHA
                hasError={reCaptchaError}
                sitekey={config.recaptchaSiteKey}
                onChange={onReCaptchaResponse}
              />
            </StyledReCAPTCHAContainer>
            {reCaptchaError && (
              <ErrorMessage
                data-testid="recaptchaErrorMessage"
                as="div"
                fontSize={{ base: fontSizes.base }}
                fontWeight={fontWeights.normal}
                lineHeight={1.5}
                color={colors.error500}
              >
                {reviewClaimCopy.reCaptchaError}
              </ErrorMessage>
            )}
          </div>
        )}
        <Button
          aria-label="next button"
          fullWidth={true}
          size="2xl"
          className="submit-button"
          type="submit"
          disabled={isSendCodeButtonDisabled}
          onClick={() => {
            if (user.id) {
              submitClaim();
            } else {
              if (
                reCaptchaResponseValue === "" ||
                reCaptchaResponseValue === null ||
                reCaptchaResponseValue === undefined
              ) {
                setReCaptchaError(true);
                return;
              } else {
                setSendCodeButtonDisabled(true);
                setReCaptchaError(false);
                const registerUser = async () => {
                  const req: RegisterRequest = {
                    first_name: firstName,
                    last_name: lastName,
                    email: email,
                    phone_number: phoneNumber,
                    recaptcha: reCaptchaResponseValue,
                  };
                  await setCsrf();
                  sendRegister(req);
                };
                registerUser();
              }
            }
          }}
        >
          {user.id ? reviewClaimCopy.nextButtonSignedIn : reviewClaimCopy.nextButton}
        </Button>
        <Button
          aria-label="back button"
          data-testid="backButton"
          fullWidth={true}
          size="lg"
          className="mt-3"
          variant="outline"
          onClick={() => {
            setStep(user.id ? "description" : "contact");
          }}
        >
          <ArrowLeft
            className="m-2"
            accessibilityTitle={reviewClaimCopy.previousButton}
            titleId="GoBackArrowLeftTitle"
            height="20px"
          />
          <Text fontWeight={fontWeights.bold}>{reviewClaimCopy.previousButton}</Text>
        </Button>
      </Content>
    );
  }
  return <FallbackComponent />;
}
