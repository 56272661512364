import { useQuery } from "@tanstack/react-query";
import classNames from "classnames";
import queryFactory from "client/hooks/data/user/queryFactory";
import { formatDateString } from "client/utils/dateUtils";
import React, { useEffect, useState } from "react";
import { Carousel } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import { getUserReturns } from "../../../actions/userReturns";
import { getShipmentById } from "../../../actions/userShipments";
import Box from "../../../components/Box";
import Button from "../../../components/Button";
import { ButtonModes } from "../../../components/Button/Button.types";
import { StatusHeader, StatusTextWrapper } from "../../../components/Cards/UserClaimCard/styles";
import { StyledCarousel } from "../../../components/ImageCarousel";
import Loader from "../../../components/Loader";
import { LocationNameDateSnapshot } from "../../../components/LocationNameDateSnapshot";
import { UserRoutesEnum } from "../../../components/Routes";
import { SmallText } from "../../../components/typography";
import * as ClaimsSectionCopy from "../../../copy/userClaimsCopy";
import { isLgQuery } from "../../../helpers/mediaQuery";
import { useUserState } from "../../../hooks/useUserState";
import { Claim, ClaimStatus, ReturnStatus, ReturnStatusStrings } from "../../../types";
import {
  ClaimsSearchResponse,
  GetUserClaimsRequest,
  GetUserReturnsRequest,
} from "../../../types/actions";
import { ReturnModeEnum } from "../../../types/models";
import UserView from "../../views/UserView";
import { setClaimsWithStatus } from "./AllClaimsByStatus";
import ClaimsSection from "./ClaimsSection";
import { ExpirationMessage, InfoWrapper, Wrapper } from "./styles";

export const stillLookingClaimsRequest: GetUserClaimsRequest = {
  filters: {
    status: [ClaimStatus.NEW, ClaimStatus.NO_MATCHES, ClaimStatus.HAS_MATCHES],
    limit: 3,
  },
};

export const matchedClaimsRequest: GetUserClaimsRequest = {
  filters: {
    status: [ClaimStatus.MATCHED],
    limit: 3,
  },
};

export const canceledClaimsRequest: GetUserClaimsRequest = {
  filters: {
    status: [ClaimStatus.CANCELED],
    limit: 3,
  },
};

export const expiredClaimsRequest: GetUserClaimsRequest = {
  filters: {
    status: [ClaimStatus.EXPIRED],
    limit: 3,
  },
};

const ClaimReturnsRequest: GetUserReturnsRequest = {
  filters: {
    status: [ReturnStatus.PENDING],
    limit: 3,
  },
};

const AllClaims: React.FC = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, dispatch] = useUserState();

  const { data: stillLookingClaimsRes, isLoading: stillLookingClaimsLoading } = useQuery(
    queryFactory.claimsFiltered(stillLookingClaimsRequest),
  );
  const { data: matchedClaimsRes, isLoading: matchedClaimsLoading } = useQuery(
    queryFactory.claimsFiltered(matchedClaimsRequest),
  );
  const { data: canceledClaimsRes, isLoading: canceledClaimsLoading } = useQuery(
    queryFactory.claimsFiltered(canceledClaimsRequest),
  );
  const { data: expiredClaimsRes, isLoading: expiredClaimsLoading } = useQuery(
    queryFactory.claimsFiltered(expiredClaimsRequest),
  );
  const [pendingReturnClaims, setPendingReturnClaims] = useState<
    ClaimsSearchResponse & { loading: boolean }
  >({
    count: 0,
    results: [],
    loading: true,
  });
  const pageIsLoading =
    stillLookingClaimsLoading ||
    matchedClaimsLoading ||
    canceledClaimsLoading ||
    expiredClaimsLoading ||
    pendingReturnClaims.loading;

  const isLg = isLgQuery();
  const history = useHistory();

  const featuredSectionItems = (
    pendingReturnClaims.results as (Claim & {
      recoveryMethod: ReturnModeEnum;
    })[]
  ).filter(claim => claim.recoveryMethod === ReturnModeEnum.PICKUP);

  useEffect(() => {
    getUserReturns(ClaimReturnsRequest).then(response => {
      if (response && response?.results?.length > 0) {
        (response.results || []).map(returnObj => {
          if (returnObj.shipment) {
            getShipmentById(returnObj.shipment).then(shipment => {
              if (shipment) {
                setClaimsWithStatus(
                  response.count,
                  returnObj,
                  shipment,
                  pendingReturnClaims,
                  setPendingReturnClaims,
                );
              }
            });
          } else {
            setClaimsWithStatus(
              response.count,
              returnObj,
              null,
              pendingReturnClaims,
              setPendingReturnClaims,
            );
          }
        });
      } else {
        setPendingReturnClaims(prev => ({
          ...prev,
          loading: false,
        }));
      }
    });
  }, []);

  return (
    <UserView
      isOverview={true}
      hidePrimaryButton={pageIsLoading || featuredSectionItems.length > 0}
      contentOverlapsHeader={featuredSectionItems.length > 0}
      subHeaderText={featuredSectionItems.length > 0 ? "Your item is ready for pickup!" : ""}
    >
      {!pageIsLoading ? (
        <Wrapper
          className={classNames("zIndex1 mx-md-auto mx-lg-0 col", {
            "mt-n4": featuredSectionItems.length > 0 && !isLg,
            "pt-45":
              featuredSectionItems.length === 0 &&
              isLg &&
              ((matchedClaimsRes && matchedClaimsRes.results.length > 0) ||
                (stillLookingClaimsRes && stillLookingClaimsRes.results.length > 0)),
          })}
          data-testid="itemRow"
        >
          {/* Featured Claim section */}
          {featuredSectionItems &&
            featuredSectionItems.slice(0, 1)?.map((claim, i) => {
              // Slice is used to only show one featured claim for now
              return (
                <Box key={i} rmvDefaultPaddingMargin className="mb-35">
                  <StatusHeader status={ReturnStatus.PENDING} className="px-35 py-25 fw500">
                    Status:
                    <StatusTextWrapper className="ms-1">
                      {ReturnStatusStrings.READY_FOR_PICKUP}
                    </StatusTextWrapper>
                  </StatusHeader>
                  <div className="p-35 d-flex flex-column">
                    <div className="d-flex flex-row g-0">
                      {claim.claimImages && claim.claimImages.length > 0 && (
                        <div className="me-25">
                          <StyledCarousel
                            touch
                            interval={null}
                            prevIcon={false}
                            nextIcon={false}
                            controls={claim.claimImages && claim.claimImages.length > 1}
                            indicators={claim.claimImages && claim.claimImages.length > 1}
                            $itemDimensions={{
                              height: isLg ? 104 : 108,
                              width: isLg ? 75 : 78,
                            }}
                          >
                            {(claim.claimImages || [])?.map((file, index) => (
                              <Carousel.Item
                                key={index}
                                style={{
                                  backgroundImage: `url(${file?.image})`,
                                }}
                              >
                                <span
                                  role="img"
                                  aria-label={`image ${1 + index} of ${claim.claimImages?.length}`}
                                />
                              </Carousel.Item>
                            ))}
                          </StyledCarousel>
                        </div>
                      )}
                      <div
                        className={classNames({
                          "full-width col-12": claim.claimImages && claim.claimImages.length > 0,
                        })}
                      >
                        <InfoWrapper>
                          <LocationNameDateSnapshot
                            location={claim.lost_location.name}
                            name={claim.name}
                            lost_date={formatDateString(claim.lost_on)}
                          />
                        </InfoWrapper>
                      </div>
                    </div>

                    <Button
                      className="mt-35"
                      text="Pickup details"
                      ariaLabel="Pickup details"
                      mode={ButtonModes.primary}
                      onClick={() => {
                        history.push(`/claim/${claim.id}`);
                      }}
                      tabIndex={0}
                    />
                  </div>
                </Box>
              );
            })}

          {featuredSectionItems && featuredSectionItems.length > 0 && (
            <SmallText className="text-center mb-45">
              Lost something? <Link to="/lost">Report lost item</Link>
            </SmallText>
          )}

          {!pageIsLoading ? (
            <>
              {pendingReturnClaims && pendingReturnClaims.results.length !== 0 && (
                <ClaimsSection
                  className="mt-5"
                  claims={pendingReturnClaims.results}
                  header={ClaimsSectionCopy.CLAIMS_RETURNS.title}
                  subheader={ClaimsSectionCopy.CLAIMS_RETURNS.subheader}
                  seeAllLink={UserRoutesEnum.CLAIMS_RETURNS}
                  showSeeAll={pendingReturnClaims.count > 3}
                  isReturns
                />
              )}

              {matchedClaimsRes && matchedClaimsRes.results.length !== 0 && (
                <ClaimsSection
                  claims={matchedClaimsRes.results}
                  header={ClaimsSectionCopy.CLAIMS_MATCHED.title}
                  subheader={ClaimsSectionCopy.CLAIMS_MATCHED.subheader}
                  seeAllLink={UserRoutesEnum.CLAIMS_MATCHED}
                  showSeeAll={matchedClaimsRes.count > 3}
                />
              )}
              {stillLookingClaimsRes && stillLookingClaimsRes.results.length !== 0 && (
                <ClaimsSection
                  claims={stillLookingClaimsRes.results}
                  header={ClaimsSectionCopy.STILL_LOOKING.title}
                  subheader={ClaimsSectionCopy.STILL_LOOKING.subheader}
                  seeAllLink={UserRoutesEnum.CLAIMS_STILL_LOOKING}
                  showSeeAll={stillLookingClaimsRes.count > 3}
                />
              )}
              {canceledClaimsRes && canceledClaimsRes.results.length !== 0 && (
                <ClaimsSection
                  claims={canceledClaimsRes.results}
                  header={ClaimsSectionCopy.CLAIMS_CANCELED.title}
                  subheader={ClaimsSectionCopy.CLAIMS_CANCELED.subheader}
                  showSeeAll={canceledClaimsRes.count > 3}
                  seeAllLink={UserRoutesEnum.CLAIMS_CANCELED}
                />
              )}
              {expiredClaimsRes && expiredClaimsRes.results.length !== 0 && (
                <ClaimsSection
                  claims={expiredClaimsRes.results}
                  header={ClaimsSectionCopy.CLAIMS_EXPIRED.title}
                  subheader={ClaimsSectionCopy.CLAIMS_EXPIRED.subheader}
                  showSeeAll={expiredClaimsRes.count > 3}
                  seeAllLink={UserRoutesEnum.CLAIMS_EXPIRED}
                />
              )}
            </>
          ) : (
            <Loader />
          )}

          {!stillLookingClaimsLoading && (
            <ExpirationMessage>
              Your claim expires if we are unable to locate your item within 90 days.
            </ExpirationMessage>
          )}
        </Wrapper>
      ) : (
        <Loader />
      )}
    </UserView>
  );
};

export default AllClaims;
