// This file started as a copy of ReviewArtifactDetails but has been
// pruned to only handle claims.

import { ExclamationCircle } from "@styled-icons/heroicons-outline";
import React, { useEffect, useState } from "react";
import { Carousel } from "react-bootstrap";
import { toast } from "react-toastify";

import { Image } from "../../../typings/endpoints/image";
import {
  ArtifactImagePreview,
  Category,
  Claim,
  Location,
  PartnerLocation,
} from "../../types/models";

import useUpdateClaim from "client/hooks/data/user/useUpdateClaim";
import { useDateFormatter } from "client/hooks/useDateFormatter";
import { isLgQuery } from "../../helpers/mediaQuery";
import { ClaimSteps, ItemDetails } from "../../scenes/public/ClaimForm/types";
import { PartnerStorageLocation, UpdateClaimRequest } from "../../types";
import { mountBodyGray6, unmountBodyGray6 } from "../../utils/bodyStyle";
import Button from "../Button";
import { ButtonModes } from "../Button/Button.types";
import CardFormFooter from "../CardFormFooter";
import { NoPhotoPlaceholder, StyledCarousel } from "../ImageCarousel";
import StepHeader from "../StepHeader";
import TextLabelValue from "../TextLabelValue/TextLabelValue";

interface ReviewClaimDetailsProps {
  existingClaim?: Claim;
  categories: Category[];
  storageLocations?: PartnerStorageLocation[];
  selectedLocation: Location | PartnerLocation;
  images: Image[];
  imageIdsToDelete?: string[];
  isSubmitEnabled: boolean;
  setStep: (p: number) => void;
  setClaimId: (id: string) => void;
  setClaimDetailsOverride: (p: ItemDetails) => void;
  setImageIdsToDelete: (value: string[] | ((prevState: string[]) => string[])) => void;
  onCancelOrBack?: () => void;
  watch: (p: string) => string;
}

export const ReviewClaimDetails: React.FC<ReviewClaimDetailsProps> = props => {
  const {
    existingClaim,
    categories,
    storageLocations,
    selectedLocation,
    images,
    imageIdsToDelete,
    isSubmitEnabled,
    setStep,
    setClaimId,
    setClaimDetailsOverride,
    setImageIdsToDelete,
    onCancelOrBack,
    watch,
  } = props;

  const isLg = isLgQuery();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const dateFormatter = useDateFormatter();

  const handleBack = () => {
    const currentItemDetails: ItemDetails = {
      foundDate: new Date(watch("lostDate")),
      itemCategoryIdValue: watch("itemCategoryIdValue"),
      storageLocationIdValue: watch("storageLocationIdValue"),
      detailedDescription: watch("detailedDescription"),
      itemName: watch("itemName"),
      images: watch("images") as unknown as ArtifactImagePreview[],
    };

    setClaimDetailsOverride(currentItemDetails);
    !!onCancelOrBack && onCancelOrBack();
    setStep(ClaimSteps.ITEM_DETAILS);
  };

  const submitText = existingClaim ? "Update" : "Submit claim";
  const artifactEditText = existingClaim ? "Back" : "Edit claim details";

  const displayError = (message: string) => {
    toast.error(<div>{message}</div>, {
      icon: <ExclamationCircle size={25} className="text-danger" />,
    });
  };

  const updateClaimMutation = useUpdateClaim();

  const onSubmitArtifact = async () => {
    setIsSubmitting(true);

    if (existingClaim) {
      const existingClaimId = existingClaim.id ?? "";
      const claimRequest: UpdateClaimRequest = {
        id: existingClaimId,
        lost_on: new Date(watch("foundDate")).toISOString().split("T")[0],
        category: watch("itemCategoryIdValue"),
        description: watch("detailedDescription"),
        name: watch("itemName"),
      };

      try {
        try {
          const data = await updateClaimMutation.mutateAsync({
            claim: claimRequest,
            deletedImageIds: imageIdsToDelete,
            createdImages: images.filter(image => image.id === undefined),
          });

          const unprocessedImageIds = data.deletedImages?.error ?? [];
          setImageIdsToDelete(unprocessedImageIds);
          if (unprocessedImageIds.length > 0) {
            throw new Error("deleting some images failed");
          }
        } catch (error) {
          throw new Error("Updating claim failed. Please try again.");
        }

        setStep(ClaimSteps.SUBMITTED);
      } catch (error) {
        displayError(error);
        setStep(ClaimSteps.REVIEW_DETAILS);
      }
      setClaimId(existingClaimId);
    }
    setIsSubmitting(false);
  };

  useEffect(() => {
    mountBodyGray6();
    return () => {
      unmountBodyGray6();
    };
  }, []);

  return (
    <div className="px-0 px-lg-35 pt-4 pb-0 rounded-0 row mx-0">
      <div
        className={`col-12 col-lg-6 px-35 pb-1 mb-3 pb-lg-0 mb-lg-0 ${
          (isLg && "border-end") || ""
        }`}
      >
        <StepHeader title={watch("itemName")} text={watch("detailedDescription")} />
        {images.length !== 0 ? (
          <StyledCarousel
            touch
            interval={null}
            prevIcon={false}
            nextIcon={false}
            controls={images.length > 1}
            indicators={images.length > 1}
            className="mt-n4"
          >
            {images?.map((file, index) => (
              <Carousel.Item key={index} style={{ backgroundImage: `url(${file?.preview})` }}>
                <span role="img" aria-label={`image ${index + 1} of ${images.length}`} />
              </Carousel.Item>
            ))}
          </StyledCarousel>
        ) : (
          <NoPhotoPlaceholder className="mt-n4">No photos added</NoPhotoPlaceholder>
        )}
      </div>
      <div className="col-12 col-lg-6 px-35">
        <TextLabelValue
          className="mb-4"
          label="Category"
          data-testid="review-item-category"
          value={categories?.find(x => String(x?.id) === watch("itemCategoryIdValue"))?.name ?? ""}
        />
        <TextLabelValue
          className="mb-4"
          label="Lost date"
          data-testid="review-item-date"
          value={dateFormatter(new Date(watch("foundDate")), "long")}
        />
        <TextLabelValue
          className="mb-4"
          label="Lost item location"
          data-testid="review-item-location"
          value={selectedLocation.name}
        />

        {storageLocations && storageLocations.length > 0 && (
          <TextLabelValue
            className="mb-0"
            label="Storage location"
            data-testid="storageLocation"
            value={
              storageLocations.find(x => String(x.id) === watch("storageLocationIdValue"))?.name ??
              ""
            }
          />
        )}
      </div>
      {isSubmitEnabled && (
        <CardFormFooter
          className="px-35 pt-3 mt-1 mt-lg-4 mb-n4 mb-lg-4 pb-3"
          leftBtnOnClick={handleBack}
          leftBtnDisabled={updateClaimMutation.isPending || isSubmitting}
          leftBtnText={isLg ? artifactEditText : undefined}
          loading={updateClaimMutation.isPending || isSubmitting}
          loadingText="Loading"
          rightBtnText={submitText}
          rightBtnDisabled={isSubmitting}
          rightBtnOnClick={onSubmitArtifact}
        />
      )}
      {!isSubmitEnabled && (
        <div className="col-12 col-lg-6 py-1 mt-1 d-flex justify-content-center">
          <Button
            text={artifactEditText}
            ariaLabel={artifactEditText}
            mode={ButtonModes.open}
            className="mb-3 mt-4"
            onClick={handleBack}
          />
        </div>
      )}
    </div>
  );
};

export default ReviewClaimDetails;
