type CategoryToMessageMap = Record<
  string,
  {
    message: string;
    confirmation: string;
  }
>;

type PartnerToCategoryToMessageMap = Record<string, CategoryToMessageMap>;

const PARTNER_SHIPPING_NOTES: PartnerToCategoryToMessageMap = {
  "richmond-international-airport": {
    "*": {
      message:
        "Before proceeding with shipping, please note that Richmond International Airport only processes shipments on Tuesdays.",
      confirmation:
        "I understand that my shipping label delivery date does not include processing time.",
    },
  },
  "universal-orlando-resort": {
    "Wallets & Purses": {
      message:
        "Please note that Universal Orlando Resort does not ship credit cards or cash with wallets and purses. Any credit cards found will be securely removed and destroyed before shipment. If cash is found, it will be removed, and a check will be issued and sent to you separately.",
      confirmation: "I understand my credit cards and cash will be removed before my item is sent.",
    },
    "Bags & Luggage": {
      message:
        "Please note that Universal Orlando Resort does not ship credit cards or cash with bags and luggage. Any credit cards found will be securely removed and destroyed before shipment. If cash is found, it will be removed, and a check will be issued and sent to you separately.",
      confirmation: "I understand my credit cards and cash will be removed before my item is sent.",
    },
  },
};

export function getPartnerShippingNotes(partner?: string, category?: string) {
  if (!partner || !category) {
    return null;
  }

  const partnerReturnNotes = PARTNER_SHIPPING_NOTES[partner];
  if (!partnerReturnNotes) {
    return null;
  }

  const message = partnerReturnNotes[category];
  return message ?? partnerReturnNotes["*"];
}
