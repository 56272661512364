import {
  Address,
  ArtifactImage,
  ArtifactImagePreview,
  Category,
  Claim,
  ClaimStatus,
  Country,
  ItemStatus,
  Location,
  Match,
  MatchOrder,
  MatchStatus,
  Partner,
  PartnerClaim,
  PartnerItem,
  PartnerLocation,
  ReturnModeEnum as ModeEnum,
  ReturnModeEnum,
  ReturnStatus,
  ShipmentStatus,
  State,
  UnclaimedStatus,
  User,
  UserItem,
  UserMatch,
  UserReturn,
} from "./models";
import { PartnerClaimStatusEnum } from "./theme";

export type PartnerSearchResponse = {
  count: number;
  results: Partner[];
};

export type LocationSearchResponse = {
  count: number;
  results: Location[];
};

export type PartnerLocationSearchResponse = {
  count: number;
  results: PartnerLocation[];
};

export type PartnerStorageLocationsResponse = {
  count: number;
  results: PartnerStorageLocation[];
};

export type StorageLocationBulkUpdateResponse = {
  status: string;
  message: string;
};

export type LocationRequest = {
  partner?: string;
  search?: string;
};

export type PartnerLocationRequestFilters = {
  limit?: number;
  offset?: number;
  ordering?: string;
  search?: ItemStatus;
};

export type PartnerLocationRequest = {
  partnerId: string;
  locationId?: string;
  filters?: PartnerLocationRequestFilters;
};
export type PartnerImagesRequest = {
  partnerId: string;
  itemId: string;
};
export type ClaimsSearchResponse = {
  count: number;
  results: Claim[];
};

export type MatchesSearchResponse = {
  count: number;
  results: UserMatch[];
};

export type ReturnsSearchResponse = {
  count: number;
  results: UserReturn[];
};

export type PartnerClaimsSearchResponse = {
  count: number;
  results: PartnerClaim[];
};

export type PartnerItemsSearchResponse = {
  count: number;
  results: PartnerItem[];
};

export type MatchSearchResponse = {
  count: number;
  results: Match[];
};

export type PartnerCountResponse = {
  count: number;
  name: string;
  id: string;
}[];

export type PartnerReturnByIdRequest = {
  partnerId: string;
  returnId: string;
};

export type UpdatePartnerReturnsRequest = {
  id: string;
  partnerId: string;
  mode: ModeEnum;
};

export type PartnerReturnsResponse = {
  count: number;
  results: PartnerReturn[];
};

export type PartnerReturn = {
  id: string;
  created_at: string;
  modified_at: string;
  match: string;
  status: ReturnStatus;
  returned_at: string;
  returned_by: string;
  shipment: string;
  mode: ModeEnum;
};

export type DetailedMatch = {
  id: string;
  item: PartnerItem;
  claim: PartnerClaim;
  matched_by_user?: number;
  matched_by_engine: boolean;
  match_score: number;
  status: MatchStatus;
  address?: string;
  accepted_at: string;
  return: string;
  lost_location: PartnerLocation;
};

export type PartnerReturnWithDetails = {
  id: string;
  created_at: string;
  modified_at: string;
  match: DetailedMatch;
  status: ReturnStatus;
  returned_at: string;
  returned_by: {
    full_name: string;
    email: string;
    phone_number: string;
  };
  shipment?: PartnerShipment;
  mode: ReturnModeEnum;
};

export type PartnerReturnDetailedStorageLocationCounts = {
  count: number;
  name: string;
  storage_location_id: string;
}[];

export type PartnerReturnDetailedCategoryCounts = {
  count: number;
  name: string;
  category_id: string;
}[];

export type PartnerReturnsDetailedResponse = {
  count: number;
  results: PartnerReturnWithDetails[];
  counts_by_recovery_method: PartnerCountResponse;
  counts_by_custom_status: PartnerGenericCountResponse;
  counts_by_item_storage_location: PartnerReturnDetailedStorageLocationCounts;
  counts_by_item_category: PartnerReturnDetailedCategoryCounts;
};

export type PartnerShipment = {
  id: string;
  created_at: string;
  modified_at: string;
  return_object: string;
  status: ShipmentStatus;
  shipment_id: string;
  carrier: string;
  label_url: string;
  shipping_address_to: string;
  shipping_address_from: string;
  transactions: Transaction[];
};

export type Transaction = {
  id: string;
  status: TransactionStatus;
};

export type PartnerShipmentByIdRequest = {
  partnerId: string;
  shipmentId: string;
};

export type CategoriesSearchResponse = {
  count: number;
  results: Category[];
};

export type ImageSearchResponse = {
  count: number;
  results: ArtifactImage[];
};

export type MatchResponse = {
  id: string;
  claim: string;
  match_score: string;
  pickup_notes: string;
  status: MatchStatus;
};

export type InviteSearchResponse = {
  external_id: string;
  email: string;
  role: string;
  locations: any;
};

export type InviteRequest = {
  full_name: string;
  job_title: string;
  phone_number: string;
  password: string;
};
export type AcceptInviteRequest = {
  external_id: string;
  invite: InviteRequest;
};

export type AcceptInviteResponse = {
  id: string;
  job_title: string;
  phone_number: string;
  password: string;
};

export type AddItemRequest = {
  partner_id: string;
  item: ItemRequest;
};

export type EditItemRequest = {
  partner_id: string;
  item_id: string;
  item: Partial<ItemRequest>;
};

export type BulkItemUpdateRequest = {
  partner_id: string;
  item_ids: string[];
  set_to_unclaimed?: boolean;
  unclaimed_status?: UnclaimedStatus;
  unclaimed_status_set_at?: Date;
  suggested_unclaimed_status?: "";
};

export type ItemRequest = {
  name: string;
  description?: string;
  category: string | Category;
  lost_location?: string;
  current_location?: {
    id: string;
    name: string;
  };
  storage_location?: string | PartnerStorageLocation;
  found_on?: string;
  parcel_width?: string;
  parcel_height?: string;
  parcel_length?: string;
  parcel_weight?: string;
  unclaimed_status?: string;
  unclaimed_status_set_at?: Date;
};

export type AddOrEditItemResponse = {
  id: string;
  created_at: string;
  modified_at: string;
  name: string;
  description: string;
  category: string;
  lost_location: string;
  current_location: string;
  storage_location: string;
  found_on: string;
  lost_on: string;
  images: ArtifactImagePreview[];
};

export type AddOrUpdateClaimResponse = {
  id: string;
  created_at: string;
  modified_at: string;
  name: string;
  description: string;
  category: string;
  lost_location: string;
  current_location: string;
  storage_location: string;
  found_on?: string;
  lost_on?: string;
  images: ArtifactImage[];
};

export type AddOrUpdatePartnerItemResponse = {
  id: string;
  created_at: Date;
  modified_at: Date;
  name: string;
  description: string;
  category: Category;
  status: ItemStatus;
  lost_location: string;
  current_location?: string;
  storage_location?: string;
  found_on?: string;
  created_by: User;
  images: ArtifactImage[];
};

export type PartnerClaimsCountFilters = {
  found_on_after?: Date;
  found_on_before?: Date;
  lost_locations?: string[];
};

export type PartnerItemsCountFilters = {
  found_on_after?: Date;
  found_on_before?: Date;
  lost_locations?: string[];
  status?: ItemStatus[];
  category?: string[];
};

export type PartnerReturnsCountFilters = {
  found_on_after?: Date;
  found_on_before?: Date;
  lost_locations?: string[];
  status?: ReturnStatus;
};

export type PartnerClaimsCountByStatusRequest = {
  partnerId: string;
  filters?: PartnerClaimsCountFilters;
};

export type PartnerItemsCountByStatusRequest = {
  partnerId: string;
  filters?: PartnerItemsCountFilters;
};

export type PartnerItemsCountByCategoryRequest = {
  partnerId: string;
  filters?: PartnerItemsCountFilters;
};

export type PartnerReturnsCountByModeRequest = {
  partnerId: string;
  filters?: PartnerReturnsCountFilters;
};

export type PartnerClaimsCountByStatusResponse = {
  count: number;
  name: PartnerClaimStatusEnum;
  value: ClaimStatus;
}[];

export type PartnerItemsCountByStatusResponse = {
  count: number;
  name: string;
  value: ItemStatus;
}[];

export type PartnerItemsCountByCategoryResponse = {
  count: number;
  name: Category["name"];
  id: Category["id"];
}[];

export type PartnerItemsCountByStorageResponse = {
  count: number;
  name: string;
  id: string;
}[];

export type PartnerItemsCountByUnclaimedStatusResponse = {
  count: number;
  name: string;
  value: string;
}[];

export type PartnerGenericCountResponse = {
  count: number;
  name: string;
  value: string;
}[];

export type PartnerClaimsCountByCategoryResponse = {
  count: number;
  name: Category["name"];
  id: Category["id"];
}[];

export type PartnerReturnsCountByModeResponse = {
  count: number;
  name: ModeEnum;
}[];

export type RegisterRequest = {
  email: string;
  first_name: string;
  last_name: string;
  phone_number: string;
  recaptcha: string;
};

export type SendOtpRequest = {
  email: string;
};

export type VerifyOtpRequest = {
  email: string;
  totp: string;
};

export type VerifyOtpResponse = {
  success: string;
  TOTP: string;
};

export type GenericApiResponse = {
  success: string;
  detail: string;
};

export type CreateClaimRequest = {
  name: string;
  description: string;
  category: string;
  lost_location: string;
  lost_on: string;
};

export type UpdateClaimRequest = {
  id: string;
  name?: string;
  description?: string;
  category?: string;
  lost_location?: string;
  lost_on?: string;
};

export type UpdateClaimResponse = {
  name: string;
  description: string;
  category: string;
  lost_location: string;
  lost_on: string;
};

export type CreateClaimImageRequest = {
  claim_id: string;
  image: any;
  index: number;
};

export type DeleteClaimImageRequest = {
  claim_id: string;
  image_id: string;
};

export type DeleteItemImageRequest = {
  image_id: string;
  item_id: string;
  partner_id: string;
};

export type AddItemImageRequest = {
  partner_id: string;
  item_id: string;
  image: any;
  index: number;
};

export type CreateArtifactImageResponse = {
  id: string;
  image: string;
  index: number;
  created_at: Date;
  modified_at: Date;
};

export type PartnerResetPasswordRequest = {
  password: string;
  token: string;
  uidb64: string;
};

export type PartnerRequestPasswordResetRequest = {
  email: string;
};

// API Query Filters
export type ClaimCountByCategoryFilters = {
  lost_on_after?: string; // Date
  lost_on_before?: string; // Date
  lost_locations?: string[];
};

export type ItemCountByCategoryFilters = {
  found_on_after?: string; // Date
  found_on_before?: string; // Date
  lost_locations?: string[];
};

export type PartnerClaimFilters = {
  category?: string;
  limit?: number;
  lost_location?: string;
  offset?: number;
  ordering?: string[];
  status?: ClaimStatus[];
};

export type UserClaimFilters = {
  category?: string;
  description?: string;
  limit?: number;
  name?: string;
  offset?: number;
  ordering?: string[];
  status?: ClaimStatus[];
};

export type UserMatchFilters = {
  limit?: number;
  offset?: number;
};

export type UserReturnFilters = {
  limit?: number;
  match?: string;
  offset?: number;
  status?: ReturnStatus[];
};

export type PartnerItemFilters = {
  category?: string;
  limit?: number;
  lost_location?: string;
  offset?: number;
  ordering?: string[];
  status?: ItemStatus[];
  detailed_fields?: "return_object" | "storage_location";
  found_on_before?: string;
  found_on_after?: string;
};

export type PartnerMatchFilters = {
  claim?: string;
  item?: string;
  limit?: number;
  lost_location?: string[];
  offset?: number;
  ordering?: MatchOrder[];
  status?: MatchStatus;
};

export type PartnerReturnFilters = {
  limit?: number;
  lost_location?: string[];
  offset?: number;
  status?: ReturnStatus;
  mode?: ReturnModeEnum[];
  custom_status?: string;
  storage_location?: string;
  category?: string;
};

export type GetClaimCountByCategoryRequest = {
  partnerId: string;
  filters?: ClaimCountByCategoryFilters;
};

export type GetItemCountByCategoryRequest = {
  partnerId: string;
  filters?: ItemCountByCategoryFilters;
};

export type GetPartnerClaimsRequest = {
  partnerId: string;
  filters?: PartnerClaimFilters;
};

export type GetUserClaimsRequest = {
  filters?: UserClaimFilters;
};

export type GetUserMatchesRequest = {
  filters?: UserMatchFilters;
};

export type GetUserReturnsRequest = {
  filters?: UserReturnFilters;
};

export type GetPartnerClaimsFromIdRequest = {
  partnerId: string;
  claimId: string;
};

export type GetPartnerItemsRequest = {
  partnerId: string;
  filters?: PartnerItemFilters;
};

export type GetPartnerItemsFromIdRequest = {
  partnerId: string;
  itemId: string;
  filters?: PartnerItemFilters;
};

export type GetPartnerMatchRequest = {
  partnerId: string;
  matchId: string;
};

export type GetPartnerMatchesRequest = {
  partnerId: string;
  filters?: PartnerMatchFilters;
};

export type GetPartnerReturnsRequest = {
  partnerId: string;
  filters?: PartnerReturnFilters;
};

export type SuccessErrorResponse = {
  success: string;
  error: string;
};

export type UserItemFromIdRequest = {
  id: string;
};

export type UserItemFromIdResponse = UserItem;

export type UserMatchFromIdResponse = UserMatch;

export type UserReturnUpdateRequest = {
  returnId: string;
  mode: ReturnModeEnum;
};

export type CountriesResponse = {
  count: number;
  next: string | null;
  results: Country[];
};

export type StatesResponse = {
  count: number;
  next: string | null;
  previous: string | null;
  results: State[];
};

export type StateResponse = State;

export type MailingAddressCreationBody = Omit<Address, "id">;

export type MailingAddressResponse = Address;

export type MailingAddressCreationResponse = MailingAddressResponse;

export type ShipmentCreationBody = {
  return_object: string;
  shipping_address_to: string;
};

export type ShipmentCreationResponse = {
  id: string;
  created_at: string;
  modified_at: string;
  return_object: string;
  status: string;
  shipment_id: string;
  carrier: string;
  label_url: string;
  shipping_address_to: string;
  shipping_address_from: string;
  shipping_options: {
    rates: Array<{
      id: string;
      total: string;
      currency: string;
      rate_id: string;
      name: string;
      delivery_estimate: string;
      high_priority: boolean;
      arrives_by: null;
      attributes: string[];
      duration_terms: string;
      estimated_days: number;
      object_id: string;
      object_owner: string;
      provider: string;
      provider_image_200: string;
      provider_image_75: string;
      servicelevel: {
        name: string;
        token: string;
        terms: string;
        extended_token: string;
        parent_servicelevel: string | null;
      };
      shipment: string;
    }>;
    messages: {
      source: string;
      text: string;
    }[];
  };
  transactions: Array<{
    id: string;
    created_at: string;
    modified_at: string;
    shipment: string;
    amount: string;
    payment_intent_id: string;
    status: string;
    client_secret: string;
  }>;
};

export enum ShippingOptions {
  STANDARD = "Standard Shipping",
  FAST = "Fast Shipping",
  FASTER = "Faster Shipping",
  FASTEST = "Fastest Shipping",
  HIGH_PRIORITY = "High Priority Shipping",
}

export enum TransactionStatus {
  PENDING = "PENDING",
  SUCCESS = "SUCCESS",
}

export type ShipmentResponse = {
  id: string;
  created_at: Date;
  modified_at: Date;
  return_object: string;
  status: ShipmentStatus;
  shipment_id: string;
  carrier: string;
  label_url: string;
  shipping_address_to: string;
  shipping_address_from: string;
  tracking_number: string;
  tracking_url_provider: string;
  transactions: Array<{
    id: string;
    created_at: Date;
    modified_at: Date;
    shipment: string;
    amount: string;
    fee: string;
    payment_intent_id: string;
    status: TransactionStatus;
  }>;
};

export type CreateCheckoutBody = {
  rate_id: string;
};

export type CompleteTransactionRequest = {
  transactionId: string;
  rate: CreateCheckoutBody;
};

export type CreateCheckoutResponse = {
  id: string;
  created_at: Date;
  modified_at: Date;
  shipment: string;
  total: string;
  fee: string;
  payment_intent_id: string;
  status: string;
  client_secret: string;
  cost_components: {
    cost_type: "handling_fee" | "shipping_fee";
    amount: string;
  }[];
};

export type CancelClaimRequest = {
  canceled_reason: string;
  canceled_reason_other?: string;
};

export type GetPartnerStorageLocationRequest = {
  partnerId: string;
  storageLocationId?: string;
};

export type StorageLocationBulkUpdateRequest = {
  partnerId: string;
  payload: {
    storage_location: string;
    item_ids: string[];
  };
};

export type PartnerStorageLocation = {
  id: string;
  created_at: Date;
  modified_at: Date;
  name: string;
  location: string;
  slug: string;
};
