import { UserLoginContext } from "client/context";
import React, { useContext, useEffect } from "react";
import theme from "../../../../../theme";
import { Text } from "../../../../../ui-components";
import { StyledReactCodeInput } from "./styles";

export default function VerificationCodeInput() {
  const {
    code,
    handleVerification,
    inputCount,
    isLoading,
    setCode,
    setVerificationError,
    verificationError,
  } = useContext(UserLoginContext);

  useEffect(() => {
    if (code.length === inputCount.current) {
      handleVerification();
    }
  }, [code]);

  return (
    <div className="mb-25 d-flex justify-content-center">
      <StyledReactCodeInput
        autoFocus
        disabled={isLoading}
        fields={4}
        inputMode="numeric"
        name="verification-code"
        onChange={value => {
          setVerificationError(null);

          setCode(value);
        }}
        type="number"
        isValid={!verificationError}
        value={code}
        inputStyleInvalid={{
          borderColor: theme.ui.colors.error500,
          outlineColor: theme.ui.colors.error500,
          color: theme.ui.colors.error500,
        }}
      />

      {verificationError !== null && (
        <Text
          className="mt-2"
          color={theme.ui.colors.error500}
          fontSize="14px"
          fontWeight={400}
          lineHeight="20px"
        >
          {verificationError}
        </Text>
      )}
    </div>
  );
}
