import React, { createContext, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { useGetMe, useSendOtp, useVerifyOtp } from "../../actions/users";
import useQueryString from "../../utils/useQueryString";
import { useAuthDispatch } from "../auth";
import { setCurrentUser } from "../auth/actions";

type LoginState = "email" | "code";

type UserLoginContextType = {
  code: string;
  email: string;
  handleCodeResent: () => void;
  handleVerification: () => void;
  inputCount: React.RefObject<number>;
  isLoading: boolean;
  resendCountdown: number;
  resentCode: boolean;
  setCode: React.Dispatch<React.SetStateAction<string>>;
  setEmail: React.Dispatch<React.SetStateAction<string>>;
  setStep: React.Dispatch<React.SetStateAction<LoginState>>;
  setVerificationError: React.Dispatch<React.SetStateAction<string | null>>;
  step: LoginState;
  verificationError: string | null;
};

const countdown = 30;

export const UserLoginContext = createContext<UserLoginContextType>({
  code: "",
  email: "",
  handleCodeResent: () => undefined,
  handleVerification: () => undefined,
  inputCount: { current: 4 },
  isLoading: false,
  resendCountdown: countdown,
  resentCode: false,
  setCode: () => undefined,
  setEmail: () => undefined,
  setStep: () => undefined,
  setVerificationError: () => undefined,
  step: "email",
  verificationError: null,
});

export default function UserLoginContextProvider({ children }: { children: React.ReactNode }) {
  const query = useQueryString();
  const history = useHistory();
  const inputCount = useRef<number>(4);
  const dispatch = useAuthDispatch();
  const [sendOtp, { loading: otpLoading, data: sentOtp }, reset] = useSendOtp();
  const [verifyOtp, { data: userVerified, loading: verificationLoading }] = useVerifyOtp();
  const [getMe, { data: user }] = useGetMe();
  const [verificationError, setVerificationError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [resendCountdown, setResendCountdown] = useState<number>(countdown);
  const [resentCode, setResentCode] = useState(false);
  const [code, setCode] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [step, setStep] = useState<LoginState>("email");

  useEffect(() => {
    setIsLoading(verificationLoading || otpLoading);
  }, [verificationLoading, otpLoading]);

  useEffect(() => {
    if (step === "email") {
      setCode("");

      setVerificationError(null);

      setResentCode(false);

      setResendCountdown(countdown);

      reset();
    }
  }, [step]);

  const handleVerification = useCallback(() => verifyOtp({ email, totp: code }), [code]);

  const handleCodeResent = useCallback(() => {
    if (resentCode) return;

    sendOtp({ email });
  }, [resentCode, email]);

  useEffect(() => {
    if (sentOtp === null) return;

    if (sentOtp?.success === undefined) {
      toast.error("Something went wrong, please try again later.");
    } else {
      setResentCode(true);

      const interval = setInterval(() => {
        setResendCountdown(prev => {
          if (prev - 1 === 0) {
            setResentCode(false);
            clearInterval(interval);
            return countdown;
          }

          return prev - 1;
        });
      }, 1000);

      return () => {
        clearInterval(interval);
        setResendCountdown(countdown);
      };
    }
  }, [sentOtp]);

  useEffect(() => {
    if (userVerified === null) return;

    if (userVerified?.success === undefined) {
      setVerificationError("Incorrect passcode");
    }

    if (userVerified.success) {
      setIsLoading(true);

      getMe();
    }
  }, [userVerified]);

  useEffect(() => {
    if (user) {
      setIsLoading(true);

      setCurrentUser(dispatch, user);

      const returnUrl = query.has("returnUrl") ? (query.get("returnUrl") as string) : "/claims";

      history.push(returnUrl);
    }
  }, [user]);

  const values = useMemo<UserLoginContextType>(
    () => ({
      code,
      email,
      handleCodeResent,
      handleVerification,
      inputCount,
      isLoading,
      resendCountdown,
      resentCode,
      setCode,
      setEmail,
      setStep,
      setVerificationError,
      step,
      verificationError,
    }),
    [step, isLoading, resentCode, email, code, verificationError, resendCountdown],
  );

  return <UserLoginContext.Provider value={values}>{children}</UserLoginContext.Provider>;
}
