import { Button } from "client/ui-components";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { ButtonTypes } from "../../../../../components/Button/Button.types";
import Checkbox from "../../../../../components/Inputs/Checkbox";
import Modal from "../../../../../components/Modal";
import { Typography } from "../../../../../components/typography";

type Props = React.PropsWithoutRef<JSX.IntrinsicElements["div"]> & {
  open: boolean;
  close: () => void;
  onFormSubmit: () => void;
  partnerName: string;
  returnNotes: string;
  confirmation: string;
  shipmentName: string;
};

type FormData = {
  disclaimer: boolean;
};

export default function ReturnNotesDisclaimerModal({
  open,
  close,
  onFormSubmit,
  partnerName,
  returnNotes,
  confirmation,
  shipmentName,
}: Props) {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<FormData>();

  useEffect(() => {
    reset({
      disclaimer: false,
    });
  }, []);

  return (
    <Modal
      onXButton={() => {
        close();
        reset({
          disclaimer: false,
        });
      }}
      topBtnClassName="m-0"
      header="Shipping Disclaimer"
      show={open}
      showXButton
      bottomBtnText="Close"
      bottomBtnOnClick={() => {
        close();
        reset({
          disclaimer: false,
        });
      }}
      maxWidth="386px"
    >
      <Typography as="p" lineHeight={1.5} className="mb-0 text-center">
        You have chosen{" "}
        <Typography as="span" fontWeight={700}>
          {shipmentName}
        </Typography>
        ! We will let{" "}
        <Typography as="span" fontWeight={700}>
          {partnerName}
        </Typography>{" "}
        know immediately once you check out.
      </Typography>

      <Typography as="p" lineHeight={1.5} className="mb-0 mt-35 text-center">
        {returnNotes}
      </Typography>

      <form className="mt-35" onSubmit={handleSubmit(onFormSubmit)}>
        <fieldset>
          <Checkbox
            error={errors.disclaimer}
            {...register("disclaimer", {
              required: {
                value: true,
                message: "Please review & check this box if you want to proceed",
              },
            })}
            name="disclaimer"
            className="ps-425"
          >
            <Typography className="text-start mb-0">{confirmation}</Typography>
          </Checkbox>

          <Button
            aria-label="Continue"
            className="w-100 border-0 mt-35"
            type={ButtonTypes.submit}
            size="xl"
          >
            Continue
          </Button>
        </fieldset>
      </form>
    </Modal>
  );
}
