import { PartnerLocationFragment } from "client/hooks/data/partner/usePartnerLocationStore";
import { convertToDateIgnoringTime } from "client/utils/dateUtils";
import { ClaimFilters } from "../../../../context/FilterClaimContext/helpers";
import {
  Category,
  ClaimStatus,
  FormattedClaim,
  GetPartnerClaimsRequest,
  PartnerClaim,
  PartnerLocation,
} from "../../../../types";
import { getCategoryName, getLocationName } from "../../../../utils/mappers";

export function getClaimsParams({
  partnerUserLocation,
  partnerId,
  itemsPerPage = 12,
  firstPageIndex = 0,
  filters,
  isArchive = false,
}: {
  partnerUserLocation?: PartnerLocationFragment;
  partnerId: string;
  itemsPerPage?: number;
  firstPageIndex?: number;
  filters: ClaimFilters;
  isArchive: boolean;
}): GetPartnerClaimsRequest {
  return {
    partnerId: partnerId,
    filters: {
      limit: itemsPerPage,
      offset: firstPageIndex,
      ...(filters.status.value === "ALL"
        ? {
            status: isArchive
              ? [ClaimStatus.EXPIRED, ClaimStatus.CANCELED]
              : [
                  ClaimStatus.NEW,
                  ClaimStatus.MATCHED,
                  ClaimStatus.NO_MATCHES,
                  ClaimStatus.HAS_MATCHES,
                ],
          }
        : { status: [filters.status.value] }),
      ...(filters.category.value !== "ALL" && {
        category: filters.category.value,
      }),
      ...(!!partnerUserLocation?.id && {
        lost_location: partnerUserLocation.id,
      }),
    },
  };
}

export function formatClaims({
  claims,
  categories,
  partnerLocations,
}: {
  claims: PartnerClaim[];
  categories?: Category[];
  partnerLocations?: PartnerLocation[];
}): FormattedClaim[] {
  return claims.map(claim => ({
    ...claim,
    originalClaim: claim,
    images: claim.images.length > 0 ? [claim.images[0]] : [],
    date: convertToDateIgnoringTime(claim.lost_on),
    description: claim.description,
    categoryName:
      categories && categories.length > 0 && claim.category
        ? getCategoryName(categories, claim.category)
        : "",
    lostLocationName:
      partnerLocations && claim.lost_location
        ? getLocationName(partnerLocations, claim.lost_location) ?? ""
        : "",
  }));
}
