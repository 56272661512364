import { usePagination } from "client/hooks";
import useFetchPartnerItems from "client/hooks/data/partner/useFetchPartnerItems";
import usePartnerLocations from "client/hooks/data/partner/usePartnerLocations";
import usePartnerLocationStore from "client/hooks/data/partner/usePartnerLocationStore";
import useCategories from "client/hooks/data/user/useCategories";
import useScrollToTop from "client/hooks/useScrollToTop";
import {
  Category,
  FormattedItem,
  ItemStatus,
  PartnerItem,
  PartnerLocation,
  UnclaimedStatus,
} from "client/types";
import { convertToDateIgnoringTime } from "client/utils/dateUtils";
import { getCategoryName, getLocationName } from "client/utils/mappers";
import { formattedInventoryId } from "client/utils/stringUtils";
import useCurrentPartner from "client/utils/useCurrentPartner";
import { useEffect, useState } from "react";
import { allArchiveUnclaimedStatuses } from ".";
import { ArchiveFilterValues } from "..";
import useArchiveFilters from "./useArchiveFilters";

function formatItems({
  items,
  categories,
  partnerLocations,
}: {
  items: PartnerItem[];
  categories: Category[];
  partnerLocations?: PartnerLocation[];
}): FormattedItem[] {
  return items.map(item => {
    const status: ItemStatus | UnclaimedStatus =
      item.status === ItemStatus.UNCLAIMED && item.unclaimed_status
        ? item.unclaimed_status
        : item.status;
    return {
      originalItem: item,
      id: item.id,
      inventoryId: formattedInventoryId(item.id),
      name: item.name,
      description: item.description ?? "",
      date: convertToDateIgnoringTime(item.found_on),
      images: item.images.length > 0 ? [item.images[0]] : [],
      status,
      categoryName: categories && item.category ? getCategoryName(categories, item.category) : "",
      lostLocationName:
        partnerLocations && item.lost_location
          ? getLocationName(partnerLocations, item.lost_location) || ""
          : "",
    };
  });
}

export function generateArchiveQuery({
  partnerUserLocation,
  filters,
  itemsPerPage = 12,
  page = 1,
}: {
  partnerUserLocation;
  filters: ArchiveFilterValues;
  itemsPerPage?: number;
  page?: number;
}): Record<string, any> {
  const queryObj: Record<string, any> = {
    limit: itemsPerPage,
    offset: (page - 1) * itemsPerPage,
    status: ItemStatus.UNCLAIMED,
    category: filters.category === "ALL" ? undefined : filters.category,
    lost_location: partnerUserLocation.id || undefined,
    found_on_before: filters.date_before,
    found_on_after: filters.date_after,
    unclaimed_status:
      filters.unclaimed_status === "ALL" ? allArchiveUnclaimedStatuses : filters.unclaimed_status,
  };

  return queryObj;
}

const useArchivedItems = () => {
  const [count, setCount] = useState<number | null>(null);
  const { page, pageSize } = usePagination({
    count,
  });
  const { partnerUserLocation } = usePartnerLocationStore();
  const { partnerLocations } = usePartnerLocations(useCurrentPartner());

  const { filterValues } = useArchiveFilters();

  const {
    items,
    isLoading,
    error,
    count: totalCount,
  } = useFetchPartnerItems({
    query: generateArchiveQuery({
      partnerUserLocation,
      filters: filterValues,
      itemsPerPage: pageSize,
      page,
    }),
  });

  useEffect(() => {
    if (totalCount !== undefined) {
      setCount(totalCount);
    }
  }, [totalCount]);

  const categories = useCategories();
  const formattedItems = formatItems({
    items: items ?? [],
    categories: categories.data ?? [],
    partnerLocations,
  });

  useScrollToTop([page]);

  return {
    items: formattedItems,
    isLoading,
    error,
    count: count ?? 0,
  };
};

export default useArchivedItems;
