import ArrowLeft from "@assets/ui-icons/ArrowLeft";
import { useGetMe, useSendOtp, useVerifyOtp } from "client/actions/users";
import { LostItemCreationContext, setCurrentUser, useAuthDispatch } from "client/context";
import { isMdQuery, isXsQuery } from "client/helpers/mediaQuery";
import theme from "client/theme";
import { SendOtpRequest, VerifyOtpRequest } from "client/types";
import scrollToTop from "client/utils/scrollToTop";
import React, { useContext, useEffect, useRef, useState } from "react";
import ReactCodeInput from "react-code-input";
import { toast } from "react-toastify";
import { colors, fontSizes, fontWeights } from "../../../../../../theme/uiTheme";
import { Button, Heading, Text } from "../../../../../../ui-components";
import { OTPCopy } from "./content";
import { Content, StyledHelperText, StyledIncorrectMessage, StyledReactCodeInput } from "./styles";

export default function OTP() {
  const isXs = isXsQuery();
  const isMd = isMdQuery();
  const { email, submitClaim, setStep } = useContext(LostItemCreationContext);
  const [isPinCodeValid, setIsPinCodeValid] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isResent, setIsResent] = useState(false);
  const [pinCode, setPinCode] = useState("");
  const [counter, setCounter] = useState(30);
  const [hasError, setHasError] = useState(false);
  const [verifyOtp, { data: verifyRes, loading: verifyOtpLoading }] = useVerifyOtp();
  const [sendOtp] = useSendOtp();
  const [getMe, { data: user, error: getMeError }] = useGetMe();
  const dispatch = useAuthDispatch();
  const ref = useRef<ReactCodeInput | null>(null);

  const handlePinChange = pin => {
    setPinCode(pin);
  };

  const handleSubmit = () => {
    const verifyRequest: VerifyOtpRequest = {
      email: email,
      totp: pinCode,
    };
    void verifyOtp(verifyRequest);
  };

  scrollToTop();

  useEffect(() => {
    if (ref.current === null) return;

    (ref.current?.["textInput"] || []).forEach((input, index) => {
      input.setAttribute("aria-label", `Log in code input ${index + 1}`);
    });
  }, [ref]);

  useEffect(() => {
    if (counter === 0) {
      setIsResent(false);
    }
  }, [counter]);

  useEffect(() => {
    let timer;

    const startCountdown = () => {
      timer = setInterval(() => {
        setCounter(prevCount => prevCount - 1);
      }, 1000);
    };

    const stopCountdown = () => {
      clearInterval(timer);
      setCounter(30);
    };

    if (isResent) {
      startCountdown();
    } else {
      stopCountdown();
    }

    return () => stopCountdown();
  }, [isResent]);

  useEffect(() => {
    if (pinCode.length < 4) return setHasError(false);
    if (pinCode.length === 4 && !isSubmitted && !hasError) {
      setIsSubmitted(true);
      handleSubmit();
    }
  }, [pinCode, hasError]);

  useEffect(() => {
    if (verifyOtpLoading) return;
    if (!verifyRes?.success) {
      setHasError(true);
      setIsPinCodeValid(false);
      setIsSubmitted(false);
      setIsResent(false);
      return;
    } else {
      setHasError(false);
      setIsPinCodeValid(true);
      setIsSubmitted(true);
      void getMe();
    }
  }, [verifyRes, verifyOtpLoading]);

  useEffect(() => {
    if (!user) {
      setIsPinCodeValid(false);
      setIsSubmitted(false);
      setIsResent(false);
      getMeError && toast.error("Failed to retrieve user.");
      return;
    }
    setCurrentUser(dispatch, user);
    setIsPinCodeValid(true);
    submitClaim();
  }, [user]);

  useEffect(() => {
    if (!isPinCodeValid) {
      setIsSubmitted(false);
      setIsResent(false);
    }
  }, [isPinCodeValid]);

  return (
    <Content>
      <Text
        data-testid="otpSubheading"
        className="mb-1"
        color={colors.primary500}
        lineHeight={1.5}
        fontWeight={fontWeights.bold}
        textAlign={isMd ? "center" : "left"}
      >
        {OTPCopy.subheading}
      </Text>
      <Heading
        data-testid="otpHeading"
        variant="h2"
        className="mb-3"
        textAlign={isMd ? "center" : "left"}
      >
        {OTPCopy.heading}
      </Heading>
      <Text
        data-testid="otpInstructions"
        as="div"
        fontSize={{ base: fontSizes.base }}
        fontWeight={fontWeights.normal}
        lineHeight={1.5}
        textAlign={isMd ? "center" : "left"}
      >
        {OTPCopy.instructions}
      </Text>
      <div className="d-flex flex-column align-items-md-center justify-content-md-center">
        <div className="d-flex-md flex-column">
          <StyledReactCodeInput
            autoFocus
            ref={ref}
            name="verificationCode"
            inputMode="numeric"
            type="number"
            fields={4}
            isValid={!hasError}
            inputStyleInvalid={{
              borderColor: theme.ui.colors.error500,
              outlineColor: theme.ui.colors.error500,
              color: theme.ui.colors.error500,
            }}
            className="px-0 justify-content-between justify-content-sm-start d-flex my-3"
            onChange={handlePinChange}
            value={pinCode}
            disabled={verifyOtpLoading}
          />
          {hasError && (
            <StyledIncorrectMessage className="px-0" data-testid="incorrectCodeErrorMessage">
              {OTPCopy.codeErrorMessage}
            </StyledIncorrectMessage>
          )}
        </div>
      </div>

      <StyledHelperText
        fontWeight={theme.ui.fontWeights.normal}
        className="text-md-center mb-25"
        as="p"
      >
        <Text
          data-testid="otpResetCodeInstructions"
          as={isXs || isMd ? "span" : "span"}
          fontSize={{ base: fontSizes.base }}
          fontWeight={fontWeights.normal}
          lineHeight={1.5}
          textAlign={isMd ? "center" : "left"}
        >
          {OTPCopy.receivedCode}
        </Text>
        <Text
          data-testid="otpResendCode"
          as={isXs || isMd ? "span" : "span"}
          textAlign={isMd ? "center" : "left"}
          fontSize={{ base: fontSizes.base }}
          fontWeight={fontWeights.bold}
          lineHeight={1.5}
          color={isResent ? colors.secondary500 : colors.primary500}
          role="button"
          onClick={() => {
            setIsResent(true);
            const otpRequest: SendOtpRequest = {
              email: email,
            };
            sendOtp(otpRequest);
          }}
        >
          {" "}
          {isResent ? OTPCopy.codeResent : OTPCopy.resendCode}
        </Text>
      </StyledHelperText>

      {isResent && (
        <Text
          as="div"
          fontSize={{ base: fontSizes.sm }}
          fontWeight={fontWeights.normal}
          lineHeight={1.5}
          textAlign={isMd ? "center" : "left"}
          color={colors.gray600}
        >
          No luck? After {`${counter} second${counter > 1 ? "s" : ""}`}, you can try resending the
          code again.
        </Text>
      )}
      <Button
        aria-label="back button"
        data-testid="backButton"
        fullWidth={true}
        size={isMd ? "xl" : "2xl"}
        className="mt-3"
        variant="outline"
        onClick={() => {
          setStep("review");
        }}
      >
        <ArrowLeft
          className="m-2"
          accessibilityTitle="Go back"
          titleId="GoBackArrowLeftTitle"
          height="20px"
        />
        <Text fontWeight={fontWeights.bold}>{OTPCopy.previousButton}</Text>
      </Button>
    </Content>
  );
}
