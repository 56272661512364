import fetchClaim from "client/hooks/data/user/fetchFunctions/fetchClaim";
import { formatDateString } from "client/utils/dateUtils";
import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { getUserMatchFromId } from "../../../actions/userMatches";
import { getUserReturns } from "../../../actions/userReturns";
import Loader from "../../../components/Loader";
import { useUserState } from "../../../hooks/useUserState";
import { Claim, ReturnStatus, UserClaimStatus } from "../../../types";
import {
  ClaimsSearchResponse,
  GetUserReturnsRequest,
  TransactionStatus,
} from "../../../types/actions";
import { ShipmentStatus } from "../../../types/models";
import { getUserClaimStatusString } from "../../../utils/getUserClaimStatusString";
import UserView from "../../views/UserView";
import ClaimPill from "./ClaimPill";

const CompletedReturnsRequest: GetUserReturnsRequest = {
  filters: {
    status: [ReturnStatus.COMPLETED],
  },
};

type ReturnedClaim = Omit<Claim, "status"> & {
  returned_at: string;
  status: UserClaimStatus | undefined;
};

type ReturnedClaims = Omit<ClaimsSearchResponse, "results"> & {
  results: ReturnedClaim[];
  loading: boolean;
};

const CompletedReturns: React.FC = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, dispatch] = useUserState();
  const [returnedClaims, setReturnedClaims] = useState<ReturnedClaims>({
    count: 0,
    results: [],
    loading: true,
  });

  const history = useHistory();

  useEffect(() => {
    getUserReturns(CompletedReturnsRequest).then(response => {
      if (response) {
        response.results.length === 0 && setReturnedClaims(prev => ({ ...prev, loading: false }));
        (response.results || []).map(returnObj => {
          getUserMatchFromId(returnObj.match).then(match => {
            if (match) {
              return fetchClaim(match.claim).then(claim => {
                if (claim) {
                  setReturnedClaims(prev => ({
                    ...prev,
                    count: response.count,
                    results: [
                      ...prev.results,
                      {
                        ...claim,
                        returned_at: returnObj.returned_at,
                        status: getUserClaimStatusString({
                          recoveryMethod: returnObj.mode,
                          claim,
                          returnObject: returnObj,
                          shipment: {
                            status: ShipmentStatus.DELIVERED,
                            transactions: [
                              {
                                status: TransactionStatus.SUCCESS,
                              },
                            ],
                          },
                        }),
                      },
                    ],
                    loading: response.count === returnedClaims.results.length && false,
                  }));
                }
              });
            }
          });
        });
      }
    });
  }, []);

  return (
    <UserView
      hidePrimaryButton
      contentOverlapsHeader
      heading="Completed returns"
      subHeaderText={
        returnedClaims.loading
          ? ""
          : (() => {
              switch (returnedClaims.count) {
                case 0:
                  return `You have no recovered items`;
                case 1:
                  return `Woohoo! You’ve recovered 1 item`;
                default:
                  return `Woohoo! You’ve recovered ${returnedClaims.count} items`;
              }
            })()
      }
      useLogo
      hideMatchIndicator
      showLostSomethingLink
    >
      <div className="mt-n35 mt-lg-0">
        {returnedClaims.loading ? (
          <Loader />
        ) : returnedClaims.results.length < 1 ? (
          <div className="d-flex flex-column align-items-center mt-5">
            <p className="text-center mt-5">You have no completed returns at the moment.</p>
          </div>
        ) : (
          (returnedClaims.results || []).map((claim: ReturnedClaim, i) => {
            return (
              <ClaimPill
                buttonText="See details"
                key={i}
                id={claim.id}
                location={claim.lost_location.name}
                name={claim.name}
                lostDate={formatDateString(claim.lost_on)}
                returnDate={format(Date.parse(claim.returned_at), "PP")}
                status={claim.status}
                onClick={() => {
                  history.push({
                    pathname: `/claim/${claim.id}`,
                    state: { from: location.pathname },
                  });
                }}
              />
            );
          })
        )}
      </div>
    </UserView>
  );
};

export default CompletedReturns;
