import { WarningSign } from "@assets/icons/icons";
import { Heading, Link, Text, WarningMessage } from "client/ui-components";
import React, { useCallback, useContext, useEffect } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useCreateCheckout } from "../../../../actions/shippingCheckout";
import { ShippingCheckoutContext } from "../../../../context/ShippingCheckout";
import { getPartnerShippingNotes } from "../../ClaimDetails/UserReturnProcess/PickupAndShipping/getShippingNotes";
import { StyledParagraph } from "../BasicInfoDetail/styles";
import DeliveryOption from "./DeliveryOption";
import DisclaimerModal from "./DisclaimerModal";
import ReturnNotesDisclaimerModal from "./ReturnNotesDisclaimerModal";
import { ErrorMessage } from "./styles";

type FormDataType = {
  deliveryOptions: string;
};

type Props = React.PropsWithoutRef<JSX.IntrinsicElements["div"]> & {
  setCurrentIndex: React.Dispatch<React.SetStateAction<number>>;
};

export default function DeliveryDetail({ className, setCurrentIndex, ...props }: Props) {
  const [createCheckout, { data: checkoutResponse, loading: isCheckoutResponseLoading }] =
    useCreateCheckout();
  const { checkoutData, setCheckoutData, isLoading, setIsLoading, claim, isClaimLoading } =
    useContext(ShippingCheckoutContext);
  const [showDisclaimer, setShowDisclaimer] = React.useState<boolean>(false);
  const [showReturnNotesDisclaimer, setShowReturnNotesDisclaimer] = React.useState<
    "with-high-priority" | "no-high-priority" | "no-show"
  >("no-show");
  const [highPriorityData, setHighPriorityData] = React.useState<FormDataType>({
    deliveryOptions: "",
  });
  const country = claim?.lost_location.address.country_code;

  const {
    register,
    watch,
    formState: { errors },
    handleSubmit,
  } = useForm<FormDataType>({
    criteriaMode: "all",
    reValidateMode: "onChange",
    defaultValues: {
      deliveryOptions: "0",
    },
  });

  useEffect(() => {
    if (checkoutData.claimId === undefined || checkoutData.claimId === null) return;
  }, [checkoutData.claimId]);

  const currentSelection = checkoutData?.shippoRates?.find(
    ({ object_id }) => object_id === watch("deliveryOptions"),
  );

  const requestCheckoutCreation = useCallback(
    (data: FormDataType) => {
      if (checkoutData.shippingId == null) return;

      const selectedOption = checkoutData.shippoRates.find(
        ({ object_id }) => object_id === data.deliveryOptions,
      );
      if (selectedOption !== undefined) {
        setCheckoutData(prev => ({
          ...prev,
          selectedDeliveryMethod: {
            ...selectedOption,
          },
        }));
      }

      createCheckout(checkoutData.shippingId, {
        rate_id: data.deliveryOptions,
      });
    },
    [checkoutData?.shippingId, checkoutData?.shippoRates, createCheckout, setCheckoutData],
  );

  const onSubmit: SubmitHandler<FormDataType> = data => {
    if (checkoutData.shippingId == null) return;

    if (
      checkoutData.clientSecret !== null &&
      data.deliveryOptions === checkoutData.selectedDeliveryMethod?.id
    ) {
      setCurrentIndex(prev => prev + 1);
      return;
    }

    const selected = checkoutData.shippoRates.find(
      ({ object_id }) => object_id === data.deliveryOptions,
    );

    const isHighPriority = selected?.high_priority;
    const returnNotes = claim
      ? getPartnerShippingNotes(claim.lost_location.partner.slug, claim.category.name)
      : null;

    if (returnNotes) {
      setHighPriorityData(data);
      setShowReturnNotesDisclaimer(isHighPriority ? "with-high-priority" : "no-high-priority");
    } else if (isHighPriority) {
      setHighPriorityData(data);
      setShowDisclaimer(true);
    } else {
      requestCheckoutCreation(data);
    }
  };

  useEffect(() => {
    if (checkoutResponse !== null) {
      setCheckoutData(prev => ({
        ...prev,
        cost_components: checkoutResponse.cost_components,
        clientSecret: checkoutResponse.client_secret,
        paymentIntentId: checkoutResponse.payment_intent_id,
        transactionId: checkoutResponse.id,
      }));

      setCurrentIndex(prev => prev + 1);
    }
  }, [checkoutResponse, setCheckoutData, setCurrentIndex]);

  useEffect(() => {
    setIsLoading(isCheckoutResponseLoading);
  }, [isCheckoutResponseLoading, setIsLoading]);

  if (!isLoading && !checkoutData?.shippoRates?.length) {
    return (
      <ErrorMessage>
        <WarningSign width={54} height={54} accessibilityTitle="warning sign" titleId="warning" />
        <Text textAlign="center" fontWeight={500}>
          Sorry, we are having trouble fetching delivery rates from our shipping partners at the
          moment. Please try again in 30 minutes.
        </Text>
        <Text textAlign="center" fontWeight={500}>
          If you’re still experiencing issues, email our Support Team at{" "}
          <Link href="mailto:help@thanksboomerang.com">help@thanksboomerang.com</Link> and we’ll
          work with you on a custom delivery.
        </Text>
      </ErrorMessage>
    );
  }

  return (
    <>
      <DisclaimerModal
        shipmentName={`${currentSelection?.provider || ""} ${
          currentSelection?.servicelevel?.name || ""
        }`}
        partnerName={
          isClaimLoading || claim === null ? "" : claim?.lost_location?.partner?.name || ""
        }
        onFormSubmit={() => {
          requestCheckoutCreation(highPriorityData);
          setShowDisclaimer(false);
        }}
        open={showDisclaimer}
        setOpen={setShowDisclaimer}
      />

      <ReturnNotesDisclaimerModal
        partnerName={
          isClaimLoading || claim === null ? "" : claim?.lost_location?.partner?.name || ""
        }
        confirmation={
          claim
            ? getPartnerShippingNotes(claim.lost_location.partner.slug, claim.category.name)
                ?.confirmation || ""
            : ""
        }
        returnNotes={
          claim
            ? getPartnerShippingNotes(claim.lost_location.partner.slug, claim.category.name)
                ?.message || ""
            : ""
        }
        onFormSubmit={() => {
          if (showReturnNotesDisclaimer === "no-high-priority") {
            requestCheckoutCreation(highPriorityData);
          } else {
            setShowDisclaimer(true);
          }
          setShowReturnNotesDisclaimer("no-show");
        }}
        open={showReturnNotesDisclaimer !== "no-show"}
        close={() => setShowReturnNotesDisclaimer("no-show")}
        shipmentName={currentSelection?.name || ""}
      />

      <div {...props} className={`${className && className} row w-100 mx-0`}>
        <div className="col px-0 gap-2">
          <div className="d-flex flex-column flex-sm-row justify-content-between gap-2">
            <Heading variant="h5" fontWeight={700} style={{ lineHeight: "16px" }}>
              Select a delivery option
            </Heading>
            <StyledParagraph>Note: Shipping rates are subject to change daily.</StyledParagraph>
          </div>
          {checkoutData.shippingMessages &&
            checkoutData.shippingMessages.length > 0 &&
            checkoutData.shippingMessages
              .filter(message => message.source !== "Shippo")
              .map((message, index) => <WarningMessage key={index}>{message.text}</WarningMessage>)}
          <form id="deliveryOptions" onSubmit={handleSubmit(onSubmit)}>
            <fieldset disabled={isLoading} className="d-flex flex-column gap-25">
              {(checkoutData.shippoRates || [])
                .sort((a, b) => {
                  return Number(a.total) - Number(b.total);
                })
                .map(rate => (
                  <DeliveryOption
                    key={rate.object_id}
                    rate={rate}
                    hasRadio
                    radioOptions={{
                      ...register("deliveryOptions", {
                        required: "Please select a delivery option",
                      }),
                      value: rate.object_id,
                    }}
                    country={country}
                  />
                ))}
            </fieldset>
          </form>

          {errors.deliveryOptions && (
            <div className="text-danger invalid-feedback d-block fw500">
              {errors.deliveryOptions.message}
            </div>
          )}
        </div>
      </div>
    </>
  );
}
