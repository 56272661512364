import { ClaimDetailsContext } from "client/context/ClaimDetails";
import { WarningMessage } from "client/ui-components";
import React, { Dispatch, SetStateAction, useContext } from "react";
import { TruckWithGreenCheck } from "../../../../../assets/icons/icons";
import Button from "../../../../../components/Button";
import { ButtonModes } from "../../../../../components/Button/Button.types";
import Modal from "../../../../../components/Modal";
import { ModalHeaderTextEnum } from "../../../../../components/Modal/Modal.types";
import { shippingCopy } from "../../../../../copy/userReturnProcessCopy";
import { ReturnModeEnum } from "../../../../../types";
import Pickup from "../Pickup";
import { getPartnerShippingNotes } from "./getShippingNotes";
import { ShippingWrapper } from "./styles";

interface PickupAndShippingProps {
  selectedRecoveryMethod: ReturnModeEnum | undefined;
  changeRecoveryMethod: Dispatch<SetStateAction<ReturnModeEnum>>;
  recoveryMethods?: ReturnModeEnum[];
  setShow: Dispatch<SetStateAction<boolean>>;
  shippingInstructions?: string;
  show: boolean;
  handleApprove: () => void;
  approveLoading: boolean;
}

const PickupAndShipping: React.FC<PickupAndShippingProps> = ({
  changeRecoveryMethod,
  selectedRecoveryMethod,
  setShow,
  shippingInstructions,
  show,
  handleApprove,
  approveLoading,
}) => {
  const { claim } = useContext(ClaimDetailsContext);
  const partner = claim?.lost_location.partner.slug;
  const category = claim?.category.name;
  return (
    <Modal
      show={show}
      header={ModalHeaderTextEnum.USER_RECOVERY_METHOD}
      showXButton={true}
      onXButton={() => setShow(false)}
      wideContentDesktop={selectedRecoveryMethod === ReturnModeEnum.PICKUP}
    >
      {/* If default is pickup show this */}
      {selectedRecoveryMethod === ReturnModeEnum.PICKUP ? (
        <Pickup
          modalVersion
          changeRecoveryMethod={changeRecoveryMethod}
          handleApprove={handleApprove}
          approveLoading={approveLoading}
        />
      ) : null}
      {selectedRecoveryMethod === ReturnModeEnum.SHIPPING ? (
        <>
          <WarningMessage>{getPartnerShippingNotes(partner, category)?.message}</WarningMessage>
          <ShippingWrapper className="mb-35 pt-lg-2">
            <div className="border rounded-3 py-4 px-35 mb-35">
              <div className="mb-35 pt-1">
                <div className="d-flex align-items-center mb-1">
                  <TruckWithGreenCheck
                    titleId="ready-to-ship-icon"
                    accessibilityTitle="Ready to pack icon"
                  />
                  <h3 className={"fw-bold ms-25 fs-lg mb-0"} data-testid="subheader">
                    Ship my item
                  </h3>
                </div>
                <p className="copy mb-0" data-testid="subheaderHelperText">
                  {shippingInstructions || shippingCopy.SHIPPING_TAGLINE}
                </p>
              </div>
              <Button
                className="w-100 mt-35 mb-2"
                ariaLabel="Ship my item"
                text={shippingCopy.SHIP_MY_ITEM_BUTTON}
                mode={ButtonModes.primary}
                tabIndex={0}
                onClick={handleApprove}
                loading={approveLoading}
                disabled={approveLoading}
              />
            </div>
            <p className="text-center pb-35">
              {shippingCopy.PICK_IT_UP_DESCRIPTION}{" "}
              <span
                role="button"
                className="cursor-pointer link-primary fw-bold"
                onClick={() => changeRecoveryMethod(ReturnModeEnum.PICKUP)}
                tabIndex={0}
                data-testid="pickItUpLink"
              >
                {shippingCopy.PICK_IT_UP_BUTTON}
              </span>
            </p>
          </ShippingWrapper>
        </>
      ) : null}
    </Modal>
  );
};

export default PickupAndShipping;
