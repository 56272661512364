import React from "react";
import { WarningCircleIcon } from "../../assets/icons/icons";
import theme from "../../theme";

export interface WarningMessageProps {
  children: React.ReactNode;
}

const WarningMessage: React.FC<WarningMessageProps> = ({ children }) => {
  if (!children) return null;
  return (
    <div
      className="invalid-feedback d-block fw500 d-flex align-items-center gap-2 p-2 rounded-4 my-2"
      style={{
        color: theme.ui.colors.yellow600,
        backgroundColor: theme.ui.colors.yellow400,
        border: `1px solid ${theme.ui.colors.yellow500}`,
        lineHeight: "1.5",
      }}
    >
      <WarningCircleIcon
        accessibilityTitle="warning icon"
        titleId="warningIcon"
        color={theme.ui.colors.yellow600}
      />
      {children}
    </div>
  );
};

export default WarningMessage;
