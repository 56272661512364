import { isLgQuery, isMdQuery, isXsQuery } from "client/helpers/mediaQuery";
import { useDateFormatter } from "client/hooks/useDateFormatter";
import ImageCarousel from "client/ui-components/ImageCarousel";
import Typography from "client/ui-components/Typography";
import { convertToDateIgnoringTime } from "client/utils/dateUtils";
import { formattedInventoryId } from "client/utils/stringUtils";
import React from "react";
import {
  BoomerangArrowRight,
  Check,
  CloseBig,
  EmailIcon,
  PhoneIcon,
} from "../../assets/icons/icons";
import theme from "../../theme";
import { PartnerClaim, PartnerItem, PartnerLocation } from "../../types/models";
import formatPhoneNumber from "../../utils/formatPhoneNumber";
import { LegacyTextComponent, TextBook } from "../typography";
import {
  MatchingCardWrapper,
  StyledAccept,
  StyledDecline,
  StyledFooterActions,
  StyledLabel,
  StyledMatchingCard,
} from "./styles";

interface MatchingCardProps {
  useSquarePlaceholders?: boolean;
  claim: PartnerClaim;
  claimLocation?: PartnerLocation;
  matchedKeywords?: Array<string>;
  matchingClaimToItems: boolean;
  item: PartnerItem;
  itemLocation?: PartnerLocation;
  onAcceptMatch: () => void;
  onDeclineMatch: () => void;
}

const ClaimDetailsSection: React.FC<{
  description: string;
  fullName: string;
  email: string;
  phoneNumber?: string;
}> = ({ description, fullName, email, phoneNumber }) => {
  return (
    <>
      <Typography
        className="text-center mt-15 mb-0"
        as="p"
        color={theme.ui.colors.primary700}
        fontWeight={theme.ui.fontWeights.normal}
        fontSize="16px"
        lineHeight={1.5}
      >
        {description}
      </Typography>
      <div className="pt-3">
        <Typography
          className="text-center pb-2 pt-1 mb-0"
          as="p"
          color={theme.ui.colors.gray600}
          fontWeight={theme.ui.fontWeights.normal}
          fontSize={theme.ui.fontSizes.sm}
          lineHeight={1.45}
        >
          Contact details
        </Typography>
        <Typography
          style={{ paddingBottom: "4px" }}
          className="text-center px-25 pt-1 mb-0"
          as="p"
          color={theme.ui.colors.primary700}
          fontWeight={theme.ui.fontWeights.bold}
          fontSize={theme.ui.fontSizes.sm}
          lineHeight={1.2}
        >
          {fullName}
        </Typography>
        {email && (
          <div className="d-flex flex-sm-row gap-2 align-items-center justify-content-center">
            <EmailIcon
              className="align-self-center"
              color={theme.ui.colors.primary700}
              height="16px"
              width="16px"
              accessibilityTitle="Email"
              titleId="ContactDetailsSnapshotEmailTitle"
            />
            <Typography
              style={{ paddingBottom: "4px" }}
              className="mb-0"
              as="p"
              color={theme.ui.colors.gray600}
              fontWeight={theme.ui.fontWeights.normal}
              fontSize={theme.ui.fontSizes.sm}
              lineHeight={1.5}
            >
              {email}
            </Typography>
          </div>
        )}
        <div className="d-flex flex-sm-row gap-2 align-items-center justify-content-md-center">
          <PhoneIcon
            className="align-self-start"
            color={theme.ui.colors.primary700}
            height="18px"
            width="18px"
            accessibilityTitle="Phone number"
            titleId="ContactDetailsSnapshotPhoneNumberTitle"
          />
          <Typography
            as="p"
            color={theme.ui.colors.gray600}
            fontWeight={theme.ui.fontWeights.normal}
            fontSize={theme.ui.fontSizes.sm}
            lineHeight={1.5}
          >
            {phoneNumber ? formatPhoneNumber(phoneNumber) : "---"}
          </Typography>
        </div>
      </div>
    </>
  );
};

const MatchingCard: React.FC<MatchingCardProps> = props => {
  const {
    claim,
    claimLocation,
    matchedKeywords,
    matchingClaimToItems,
    item,
    itemLocation,
    onAcceptMatch,
    onDeclineMatch,
  } = props;

  const dateFormatter = useDateFormatter();
  const isXs = isXsQuery();
  const isMd = isMdQuery();
  const isLg = isLgQuery();

  return (
    <MatchingCardWrapper
      className="pb-lg-0 pt-md-35
    "
    >
      <StyledMatchingCard
        className={`my-2  px-lg-0 pt-lg-0 mx-xl-auto row mx-0 bg-white ${
          isXs ? "gx-25" : ""
        } pt-35 position-relative`}
      >
        <div className="col-6">
          <Typography
            className={`d-flex gap-25 ${isMd && "ps-5"} ${isLg && "mt-45"}`}
            as="h2"
            heading
            color={theme.ui.colors.primary700}
            fontWeight={theme.ui.fontWeights.bold}
            fontSize={theme.ui.fontSizes.lg}
            lineHeight={1.5}
          >
            {matchingClaimToItems ? "Claim" : isMd ? "Found item" : "Found"}
          </Typography>
          <div
            className={`col-auto d-flex align-items-center justify-content-center py-1 mt-md-3 pe-lg-0 ${
              isMd ? " pe-4" : isXs ? "pe-25" : "pe-2"
            } `}
          >
            <ImageCarousel
              images={(matchingClaimToItems ? claim.images : item.images).map(image => image.image)}
              touch
              className="minw110"
              controls={matchingClaimToItems ? claim.images.length > 1 : item.images.length > 1}
              indicators={matchingClaimToItems ? claim.images.length > 1 : item.images.length > 1}
              dimensions={
                isMd
                  ? { height: 338, width: 246 }
                  : {
                      height: 202,
                      width: 147,
                    }
              }
              key={matchingClaimToItems ? item.id : claim.id}
              id={matchingClaimToItems ? item.id : claim.id}
            />
          </div>
        </div>
        <div className="col-6">
          <Typography
            className={`d-flex gap-25 align-items-center ${
              isLg ? "ps-4 mt-45" : isMd ? "ps-7" : ""
            } `}
            as="h2"
            heading
            color={theme.ui.colors.primary700}
            fontWeight={theme.ui.fontWeights.bold}
            fontSize={theme.ui.fontSizes.lg}
            lineHeight={1.5}
          >
            <BoomerangArrowRight
              accessibilityTitle={matchingClaimToItems ? "Found item" : "Claim"}
              ariaHidden={true}
              titleId="ClaimFoundIconTitle"
            />
            {matchingClaimToItems ? (isMd ? "Found item" : "Found") : "Claim"}
          </Typography>
          <div
            className={`col-auto d-flex align-items-center justify-content-center py-1 mt-md-3 pe-lg-3 ps-lg-0 ${
              isMd ? " ps-45" : isXs ? "ps-25" : "pe-2"
            } `}
          >
            <ImageCarousel
              images={(!matchingClaimToItems ? claim.images : item.images).map(
                image => image.image,
              )}
              touch
              className="minw110"
              controls={!matchingClaimToItems ? claim.images.length > 1 : item.images.length > 1}
              indicators={!matchingClaimToItems ? claim.images.length > 1 : item.images.length > 1}
              dimensions={
                isMd
                  ? { height: 338, width: 246 }
                  : {
                      height: 202,
                      width: 147,
                    }
              }
              key={matchingClaimToItems ? item.id : claim.id}
              id={matchingClaimToItems ? item.id : claim.id}
            />
          </div>
        </div>
        <MatchingCardComparison
          bold
          leftSideText={matchingClaimToItems ? claim.name : item.name}
          rightSideText={matchingClaimToItems ? item.name : claim.name}
          title="Name"
        />
        <MatchingCardComparison
          borderTop
          leftSideText={
            matchingClaimToItems ? (
              <ClaimDetailsSection
                description={claim.description}
                fullName={claim.user.full_name}
                email={claim.user.email}
                phoneNumber={claim.user.phone_number}
              />
            ) : (
              item.description
            )
          }
          rightSideText={
            !matchingClaimToItems ? (
              <ClaimDetailsSection
                description={claim.description}
                fullName={claim.user.full_name}
                email={claim.user.email}
                phoneNumber={claim.user.phone_number}
              />
            ) : (
              <>
                <Typography
                  className="text-center mt-15"
                  as="p"
                  color={theme.ui.colors.primary700}
                  fontWeight={theme.ui.fontWeights.normal}
                  fontSize="16px"
                  lineHeight={1.5}
                >
                  {item.description}
                </Typography>
                <div className="mt-35">
                  <StyledLabel>ID</StyledLabel>
                  <Typography
                    className="text-center mt-1 mb-0c"
                    as="p"
                    color={theme.ui.colors.primary700}
                    fontWeight={theme.ui.fontWeights.bold}
                    fontSize={theme.ui.fontSizes.sm}
                    lineHeight={1.2}
                  >
                    {formattedInventoryId(item.id)}
                  </Typography>
                </div>
              </>
            )
          }
          title="Details"
        />
        <MatchingCardComparison
          borderTop
          leftSideSubTitle={`Date ${matchingClaimToItems ? "lost" : "found"}`}
          leftSideText={
            <Typography
              className="text-center mb-25 mb-0"
              as="p"
              color={theme.ui.colors.primary700}
              fontWeight={theme.ui.fontWeights.bold}
              fontSize={theme.ui.fontSizes.base}
              lineHeight={1.5}
            >
              {dateFormatter(
                convertToDateIgnoringTime(matchingClaimToItems ? claim.lost_on : item.found_on),
                "long",
              )}
            </Typography>
          }
          rightSideSubTitle={`Date ${matchingClaimToItems ? "found" : "lost"}`}
          rightSideText={
            <Typography
              className="text-center mb-0"
              as="p"
              color={theme.ui.colors.primary700}
              fontWeight={theme.ui.fontWeights.bold}
              fontSize={theme.ui.fontSizes.base}
              lineHeight={1.5}
            >
              {dateFormatter(
                convertToDateIgnoringTime(matchingClaimToItems ? item.found_on : claim.lost_on),
                "long",
              )}
            </Typography>
          }
          title="Date"
        />
        <MatchingCardComparison
          borderTop
          leftSideSubTitle={`Location ${matchingClaimToItems ? "lost" : "found"}`}
          leftSideText={
            matchingClaimToItems
              ? claimLocation && (
                  <Typography
                    className="text-center mt-1 mb-0"
                    as="p"
                    color={theme.ui.colors.primary700}
                    fontWeight={theme.ui.fontWeights.bold}
                    fontSize={theme.ui.fontSizes.base}
                    lineHeight={1.5}
                  >
                    {`${claimLocation.name}`}
                    <br />
                    {`${claimLocation.address.address1}${
                      claimLocation.address.address2 ? "\n" : ""
                    }${claimLocation.address.address2 ?? ""}
            ${claimLocation.address.city}, ${
              claimLocation.address.region_code
            } ${claimLocation.address.postal_code}`}
                  </Typography>
                )
              : itemLocation && (
                  <Typography
                    className="text-center mb-0"
                    as="p"
                    color={theme.ui.colors.primary700}
                    fontWeight={theme.ui.fontWeights.bold}
                    fontSize="16px"
                    lineHeight={1.5}
                  >
                    {`${itemLocation.name}`}
                    <br />
                    {`${itemLocation.address.address1} ${
                      itemLocation.address.address2 ? "\n" : ""
                    }${itemLocation.address.address2 ?? ""}
          ${itemLocation.address.city}, ${
            itemLocation.address.region_code
          } ${itemLocation.address.postal_code}`}
                  </Typography>
                )
          }
          rightSideSubTitle={`Location ${matchingClaimToItems ? "found" : "lost"}`}
          rightSideText={
            !matchingClaimToItems
              ? claimLocation && (
                  <Typography
                    className="text-center mt-1 mb-0"
                    as="p"
                    color={theme.ui.colors.primary700}
                    fontWeight={theme.ui.fontWeights.bold}
                    fontSize="16px"
                    lineHeight={1.5}
                  >
                    {`${claimLocation.name}`}
                    <br />
                    {`${claimLocation.address.address1}${
                      claimLocation.address.address2 ? "\n" : ""
                    }${claimLocation.address.address2 ?? ""}
            ${claimLocation.address.city}, ${
              claimLocation.address.region_code
            } ${claimLocation.address.postal_code}`}
                  </Typography>
                )
              : itemLocation && (
                  <Typography
                    className="text-center mt-1 mb-0"
                    as="p"
                    color={theme.ui.colors.primary700}
                    fontWeight={theme.ui.fontWeights.bold}
                    fontSize="16px"
                    lineHeight={1.5}
                  >
                    {`${itemLocation.name}`}
                    <br />
                    {`${itemLocation.address.address1} ${
                      itemLocation.address.address2 ? "\n" : ""
                    }${itemLocation.address.address2 ?? ""}
          ${itemLocation.address.city}, ${
            itemLocation.address.region_code
          } ${itemLocation.address.postal_code}`}
                  </Typography>
                )
          }
          title="Location"
        />
        {matchedKeywords && matchedKeywords.length > 0 && (
          <div className="col-12 text-center border-top py-35 py-md-5">
            <StyledLabel className="pb-25">Matched Keywords</StyledLabel>
            {(matchedKeywords || []).map((keyword, i) => {
              return <TextBook key={i}>{keyword}</TextBook>;
            })}
          </div>
        )}
        <StyledFooterActions>
          <div className="wrapper">
            <StyledDecline onClick={onDeclineMatch}>
              <CloseBig
                accessibilityTitle="Decline match"
                titleId="DeclineMatchIconTitle"
                data-testid="declineMatchIcon"
              />
            </StyledDecline>
            <StyledAccept onClick={onAcceptMatch}>
              <Check
                accessibilityTitle="Accept match"
                titleId="AcceptMatchIconTitle"
                data-testid="acceptMatchIcon"
              />
            </StyledAccept>
          </div>
        </StyledFooterActions>
      </StyledMatchingCard>
    </MatchingCardWrapper>
  );
};

interface MatchingCardComparisonProps {
  bold?: boolean;
  borderTop?: boolean;
  leftSideSubTitle?: string;
  leftSideText?: string | JSX.Element;
  rightSideSubTitle?: string;
  rightSideText?: string | JSX.Element;
  title: string;
}

const MatchingCardComparison: React.FC<MatchingCardComparisonProps> = props => {
  const {
    bold,
    borderTop,
    leftSideSubTitle,
    leftSideText,
    rightSideSubTitle,
    rightSideText,
    title,
  } = props;

  const isLg = isLgQuery();

  const comparisonSide = (leftSide: boolean) => {
    const subTitle = leftSide ? leftSideSubTitle : rightSideSubTitle;
    const text = leftSide ? leftSideText : rightSideText;
    return (
      <div className="col-6 text-center pb-35">
        <div className={`${leftSide ? "border-end" : ""} h-100`}>
          {subTitle ? (
            <>
              <Typography
                style={{ paddingBottom: "1px" }}
                className="text-center px-25 mb-0"
                as="p"
                color={theme.ui.colors.gray600}
                fontWeight={theme.ui.fontWeights.normal}
                fontSize={theme.ui.fontSizes.sm}
                lineHeight={1.5}
                data-testid="label"
              >
                {subTitle}
              </Typography>
              <Typography
                className="text-center mb-4 px-25"
                as="p"
                color={theme.ui.colors.gray600}
                fontWeight={theme.ui.fontWeights.normal}
                fontSize={theme.ui.fontSizes.lg}
                lineHeight={1.5}
                data-testid="labelValue"
              >
                {text}
              </Typography>
            </>
          ) : bold ? (
            <Typography
              className="text-center px-25"
              as="p"
              color={theme.ui.colors.primary700}
              fontWeight={theme.ui.fontWeights.bold}
              fontSize="16px"
              lineHeight={1.5}
            >
              {text}
            </Typography>
          ) : (
            <LegacyTextComponent className="px-25">{text}</LegacyTextComponent>
          )}
        </div>
      </div>
    );
  };

  return (
    <div
      style={{
        paddingLeft: `${isLg ? "28px" : "33px"}`,
        paddingRight: `${isLg ? "28px" : "33px"}`,
      }}
    >
      <div className={`col-12 text-center ${borderTop ? "border-top" : ""} pt-35`}>
        <Typography
          className="text-center px-25 mb-15"
          as="p"
          color={theme.ui.colors.gray600}
          fontWeight={theme.ui.fontWeights.bold}
          fontSize="16px"
          lineHeight={1.5}
        >
          {title}
        </Typography>
      </div>
      <div className="d-flex">
        {comparisonSide(true)}
        {comparisonSide(false)}
      </div>
    </div>
  );
};

export default MatchingCard;
