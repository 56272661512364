import { ExclamationCircle } from "@styled-icons/heroicons-outline";
import React, { useEffect, useRef, useState } from "react";
import { Carousel } from "react-bootstrap";
import { toast } from "react-toastify";
import { compressImage } from "../../utils/compressImage";

import { Image } from "../../../typings/endpoints/image";
import {
  ArtifactImage,
  ArtifactImagePreview,
  Category,
  Claim,
  Location,
  PartnerItem,
  PartnerLocation,
} from "../../types/models";

import { useDateFormatter } from "client/hooks/useDateFormatter";
import { useHistory } from "react-router-dom";
import { ApiHandlerRes } from "../../actions";
import { isLgQuery } from "../../helpers/mediaQuery";
import { ItemSteps } from "../../scenes/private/ItemForm/types";
import { ClaimSteps, ItemDetails } from "../../scenes/public/ClaimForm/types";
import {
  AddItemImageRequest,
  AddItemRequest,
  CreateClaimImageRequest,
  CreateClaimRequest,
  DeleteClaimImageRequest,
  DeleteItemImageRequest,
  EditItemRequest,
  ImageSearchResponse,
  PartnerStorageLocation,
  UpdateClaimRequest,
  UpdateClaimResponse,
} from "../../types";
import { mountBodyGray6, unmountBodyGray6 } from "../../utils/bodyStyle";
import { blobRegex } from "../../utils/regEx";
import useCurrentPartner from "../../utils/useCurrentPartner";
import Button from "../Button";
import { ButtonModes } from "../Button/Button.types";
import CardFormFooter from "../CardFormFooter";
import { NoPhotoPlaceholder, StyledCarousel } from "../ImageCarousel";
import StepHeader from "../StepHeader";
import TextLabelValue from "../TextLabelValue/TextLabelValue";

interface ReviewArtifactDetailsProps {
  categories: Category[];
  storageLocations?: PartnerStorageLocation[];
  images: Image[];
  existingArtifactImages: ImageSearchResponse | undefined;
  setIsEditing: (p: boolean) => void;
  setStep: (p: number) => void;
  watch: (p: string) => string;
  setArtifactDetailsOverride: (p: ItemDetails) => void;
  selectedLocation: Location | PartnerLocation;
  submitArtifact: boolean;
  setArtifactId: (id: string) => void;
  artifact?: Claim | PartnerItem;
  imageIdsToDelete?: string[];
  isClaim: boolean;
  setImageIdsToDelete: (value: string[] | ((prevState: string[]) => string[])) => void;
  onArtifactUpdated: (
    artifact: Claim | PartnerItem,
    response: PartnerItem | UpdateClaimResponse | null,
    updatedImages: ArtifactImage[],
  ) => void;
  onCancelOrBack?: () => void;
  useCreateArtifact: () => ApiHandlerRes<
    AddItemRequest | CreateClaimRequest,
    PartnerItem | Claim | null
  >;
  useUpdateArtifact: () => ApiHandlerRes<
    EditItemRequest | UpdateClaimRequest,
    PartnerItem | UpdateClaimResponse | null
  >;
  useCreateArtifactImage: () => ApiHandlerRes<
    AddItemImageRequest | CreateClaimImageRequest,
    ArtifactImage | null
  >;
  useDeleteArtifactImage: () => ApiHandlerRes<
    DeleteItemImageRequest | DeleteClaimImageRequest,
    null
  >;
}

export const ReviewArtifactDetails: React.FC<ReviewArtifactDetailsProps> = props => {
  const {
    artifact,
    categories,
    storageLocations,
    useCreateArtifact,
    useCreateArtifactImage,
    useDeleteArtifactImage,
    existingArtifactImages,
    imageIdsToDelete,
    images,
    isClaim,
    onArtifactUpdated,
    onCancelOrBack,
    selectedLocation,
    setArtifactDetailsOverride,
    setArtifactId,
    setImageIdsToDelete,
    setIsEditing,
    setStep,
    submitArtifact,
    useUpdateArtifact,
    watch,
  } = props;
  const history = useHistory();
  const isLg = isLgQuery();
  const dateFormatter = useDateFormatter();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const handleBack = () => {
    const currentItemDetails: ItemDetails = {
      foundDate: new Date(watch("lostDate")),
      itemCategoryIdValue: watch("itemCategoryIdValue"),
      storageLocationIdValue: watch("storageLocationIdValue"),
      detailedDescription: watch("detailedDescription"),
      itemName: watch("itemName"),
      images: watch("images") as unknown as ArtifactImagePreview[],
    };
    setArtifactDetailsOverride(currentItemDetails);
    isClaim ? !!onCancelOrBack && onCancelOrBack() : setIsEditing(artifact !== undefined);
    setStep(isClaim ? ClaimSteps.ITEM_DETAILS : ItemSteps.ITEM_DETAILS);
  };

  const submitText = artifact ? "Update" : isClaim ? "Submit claim" : "Submit";
  const artifactEditText = artifact ? "Back" : isClaim ? "Edit claim details" : "Back";

  const uploadedCount = useRef(0);
  const toUploadCount = useRef(0);
  const errorCount = useRef(0);
  const partner = useCurrentPartner();

  const [createArtifact, { data: createArtifactRes, loading: createArtifactLoading }] =
    useCreateArtifact();

  const [updateArtifact, { data: updateArtifactRes, loading: updateArtifactLoading }] =
    useUpdateArtifact();

  const [
    createArtifactImage,
    {
      data: createArtifactImageRes,
      loading: createArtifactImageLoading,
      error: errorArtifactImage,
    },
  ] = useCreateArtifactImage();

  const [deleteArtifactImage, { loading: deleteArtifactImageLoading }] = useDeleteArtifactImage();

  const createNewClaim = () => {
    const claimRequest = {
      lost_on: new Date(watch("lostDate")).toISOString().split("T")[0],
      category: watch("itemCategoryIdValue"),
      description: watch("detailedDescription"),
      lost_location: selectedLocation?.id,
      name: watch("itemName"),
    };
    createArtifact(claimRequest);
  };

  const createNewItem = () => {
    const itemRequest = {
      partner_id: partner,
      item: {
        name: watch("itemName"),
        description: watch("detailedDescription"),
        category: watch("itemCategoryIdValue"),
        lost_location: selectedLocation?.id,
        current_location: {
          id: selectedLocation?.id,
          name: selectedLocation?.name,
        },
        storage_location: watch("storageLocationIdValue"),
        found_on: new Date(watch("foundDate")).toISOString().split("T")[0],
      },
    };
    createArtifact(itemRequest);
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const updateItem = (item: PartnerItem) => {
    // TODO: Is this a bug? We pass in item, but don't use it.  Instead we check 'artifact'
    if (!artifact) {
      toast.error(
        <div>
          Must has an existing {isClaim ? "claim" : "item"} to edit. Please select one to try again.
        </div>,
        {
          icon: <ExclamationCircle size={25} className="text-danger" />,
        },
      );
      return;
    }

    const itemRequest: EditItemRequest = {
      partner_id: partner,
      item_id: artifact.id,
      item: {
        name: watch("itemName"),
        description: watch("detailedDescription"),
        category: watch("itemCategoryIdValue"),
        lost_location: artifact?.lost_location as string,
        current_location: (artifact as PartnerItem)?.current_location,
        storage_location:
          watch("storageLocationIdValue") === "MOVED" ? undefined : watch("storageLocationIdValue"),
        found_on: new Date(watch("foundDate")).toISOString().split("T")[0],
      },
    };
    updateArtifact(itemRequest);
  };

  const updateClaim = (claim: Claim) => {
    const claimRequest: UpdateClaimRequest = {
      id: claim.id,
      lost_on: new Date(watch("foundDate")).toISOString().split("T")[0],
      category: watch("itemCategoryIdValue"),
      description: watch("detailedDescription"),
      name: watch("itemName"),
    };
    updateArtifact(claimRequest);
  };

  const onSubmitArtifact = () => {
    setIsSubmitted(true);
    if (artifact) {
      toUploadCount.current = images.filter(image => blobRegex.test(image.preview)).length;

      isClaim ? updateClaim(artifact as Claim) : updateItem(artifact as PartnerItem);
    } else {
      toUploadCount.current = images.length;
      isClaim ? createNewClaim() : createNewItem();
    }
  };

  useEffect(() => {
    mountBodyGray6();
    return () => {
      unmountBodyGray6();
    };
  }, []);

  useEffect(() => {
    if (!createArtifactRes) {
      return;
    }
    setArtifactId(createArtifactRes.id);
    if (images.length == 0) {
      setStep(isClaim ? ClaimSteps.SUBMITTED : ItemSteps.SUBMITTED);
      setIsSubmitted(false);
      return;
    }
    (images || []).map((image, i) => {
      compressImage(image, result => {
        const imageRequest: CreateClaimImageRequest | AddItemImageRequest = isClaim
          ? {
              claim_id: createArtifactRes.id,
              image: result,
              index: i,
            }
          : {
              partner_id: partner,
              item_id: createArtifactRes.id,
              image: result,
              index: i,
            };
        void createArtifactImage(imageRequest);
      });
    });
  }, [createArtifactRes]);

  useEffect(() => {
    if (!errorArtifactImage && !createArtifactImageRes?.id) {
      return;
    }
    if (errorArtifactImage) {
      errorCount.current += 1;
    } else {
      uploadedCount.current += 1;
    }
    if (uploadedCount.current + errorCount.current == images.length) {
      if (errorCount.current > 0) {
        toast.error(
          <div>
            Adding {isClaim ? "claim" : "item"} images has failed. Please edit to try again.
          </div>,
          {
            icon: <ExclamationCircle size={25} className="text-danger" />,
          },
        );
      }
    }
    createArtifactImageRes && existingArtifactImages?.results?.push(createArtifactImageRes);
    artifact &&
      updateArtifactRes &&
      existingArtifactImages &&
      onArtifactUpdated(artifact, updateArtifactRes, existingArtifactImages?.results);
  }, [errorArtifactImage, createArtifactImageRes]);

  useEffect(() => {
    if (!isSubmitted) {
      return;
    }

    if (uploadedCount.current == toUploadCount.current) {
      setStep(isClaim ? ClaimSteps.SUBMITTED : ItemSteps.SUBMITTED);
      setIsSubmitted(false);
    }
  }, [uploadedCount.current, createArtifactImageLoading]);

  useEffect(() => {
    let timeout;
    if (isSubmitted) {
      timeout = setTimeout(() => {
        history.push(
          isClaim ? "/partner/claims?error=network" : "/partner/inventory?error=network",
        );
        toast.error(
          <div>
            Network connection issue. Please check that your {isClaim ? "claim" : "item"} was logged
            successfully before continuing.
          </div>,
          {
            icon: <ExclamationCircle size={25} className="text-danger" />,
          },
        );
      }, 10000);
      return () => {
        clearTimeout(timeout);
      };
    }
  }, [isSubmitted]);

  useEffect(() => {
    if (!updateArtifactRes) {
      return;
    }
    if (artifact) {
      // if imageIdsToDelete for each id in imageIdsToDelete, deleteClaimImage
      if (imageIdsToDelete) {
        imageIdsToDelete.map(id => {
          deleteArtifactImage(
            isClaim
              ? { claim_id: artifact.id, image_id: id }
              : { item_id: artifact.id, image_id: id, partner_id: partner },
          );
          setImageIdsToDelete(imageIdsToDelete =>
            imageIdsToDelete.filter(imageId => imageId !== id),
          );
        });
      }
      // remove each object in existingClaimImages that has an id that matches an index in imageIdsToDelete
      const newImages =
        imageIdsToDelete &&
        existingArtifactImages?.results.filter(image => !imageIdsToDelete.includes(image.id));
      if (images.length == 0) {
        onArtifactUpdated(artifact, updateArtifactRes, newImages ?? []);
        setArtifactId(artifact.id);
        setStep(isClaim ? ClaimSteps.SUBMITTED : ItemSteps.SUBMITTED);
        setIsSubmitted(false);
        return;
      }
      images.map((v, i) => {
        if (blobRegex.test(v.preview)) {
          compressImage(v, result => {
            const imageRequest: CreateClaimImageRequest | AddItemImageRequest = isClaim
              ? {
                  claim_id: artifact.id,
                  image: result,
                  index: i,
                }
              : {
                  partner_id: partner,
                  item_id: artifact.id,
                  image: result,
                  index: i,
                };
            void createArtifactImage(imageRequest);
          });
        }
      });

      // test if images has any blobs, if not, setStep to submitted
      const hasBlobs = images.some(image => blobRegex.test(image.preview));

      setArtifactId(artifact.id);
      onArtifactUpdated(artifact, updateArtifactRes, newImages ?? []);
      if (!hasBlobs) {
        setStep(isClaim ? ClaimSteps.SUBMITTED : ItemSteps.SUBMITTED);
        setIsSubmitted(false);
      }
    }
  }, [updateArtifactRes]);

  return (
    <div className="px-0 px-lg-35 pt-4 pb-0 rounded-0 row mx-0">
      <div
        className={`col-12 col-lg-6 px-35 pb-1 mb-3 pb-lg-0 mb-lg-0 ${
          (isLg && "border-end") || ""
        }`}
      >
        <StepHeader title={watch("itemName")} text={watch("detailedDescription")} />
        {images.length !== 0 ? (
          <StyledCarousel
            touch
            interval={null}
            prevIcon={false}
            nextIcon={false}
            controls={images.length > 1}
            indicators={images.length > 1}
            className="mt-n4"
          >
            {images?.map((file, index) => (
              <Carousel.Item key={index} style={{ backgroundImage: `url(${file?.preview})` }}>
                <span role="img" aria-label={`image ${index + 1} of ${images.length}`} />
              </Carousel.Item>
            ))}
          </StyledCarousel>
        ) : (
          <NoPhotoPlaceholder className="mt-n4">No photos added</NoPhotoPlaceholder>
        )}
      </div>
      <div className="col-12 col-lg-6 px-35">
        <TextLabelValue
          className="mb-4"
          label="Category"
          data-testid="review-item-category"
          value={categories?.find(x => String(x?.id) === watch("itemCategoryIdValue"))?.name ?? ""}
        />
        <TextLabelValue
          className="mb-4"
          label={`${isClaim ? "Lost" : "Found"} date`}
          data-testid="review-item-date"
          value={dateFormatter(new Date(watch("foundDate")), "long")}
        />
        <TextLabelValue
          className="mb-4"
          label="Lost item location"
          data-testid="review-item-location"
          value={selectedLocation.name}
        />

        {storageLocations && storageLocations.length > 0 && (
          <TextLabelValue
            className="mb-0"
            label="Storage location"
            data-testid="storageLocation"
            value={
              storageLocations.find(x => String(x.id) === watch("storageLocationIdValue"))?.name ??
              ""
            }
          />
        )}
      </div>
      {submitArtifact && (
        <CardFormFooter
          className="px-35 pt-3 mt-1 mt-lg-4 mb-n4 mb-lg-4 pb-3"
          leftBtnOnClick={handleBack}
          leftBtnDisabled={
            createArtifactImageLoading ||
            createArtifactLoading ||
            isSubmitted ||
            updateArtifactLoading ||
            deleteArtifactImageLoading
          }
          leftBtnText={isLg ? artifactEditText : undefined}
          loading={
            createArtifactLoading ||
            createArtifactImageLoading ||
            isSubmitted ||
            updateArtifactLoading ||
            deleteArtifactImageLoading
          }
          loadingText={isClaim ? "Loading" : artifact ? "Updating" : "Submitting"}
          rightBtnText={submitText}
          rightBtnDisabled={
            createArtifactImageLoading ||
            createArtifactLoading ||
            isSubmitted ||
            updateArtifactLoading ||
            deleteArtifactImageLoading
          }
          rightBtnOnClick={onSubmitArtifact}
        />
      )}
      {!submitArtifact && (
        <div className="col-12 col-lg-6 py-1 mt-1 d-flex justify-content-center">
          <Button
            text={artifactEditText}
            ariaLabel={artifactEditText}
            mode={ButtonModes.open}
            className="mb-3 mt-4"
            onClick={handleBack}
          />
        </div>
      )}
    </div>
  );
};

export default ReviewArtifactDetails;
