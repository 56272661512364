import React, { useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import styled from "styled-components";

import ReCAPTCHA from "react-google-recaptcha";
import { useCsrfToken, useRegister } from "../../../../actions/users";
import Button from "../../../../components/Button";
import { ButtonModes } from "../../../../components/Button/Button.types";
import CardFormFooter from "../../../../components/CardFormFooter";
import StepHeader from "../../../../components/StepHeader";
import TextLabelValue from "../../../../components/TextLabelValue/TextLabelValue";
import { config } from "../../../../frontendConfig";
import { isLgQuery } from "../../../../helpers/mediaQuery";
import theme from "../../../../theme/index";
import { ContactDetails, RegisterRequest } from "../../../../types";
import { Content, Wrapper } from "../styles";
import { ClaimSteps } from "../types";

export const ReCAPTCHAContainer = styled.div`
  height: 78px;
  transform: scale(0.765);
  -webkit-transform: scale(0.765);
  transform-origin: 0 0;
  -webkit-transform-origin: 0 0;
  @media (min-width: ${theme.breakpoints.xs}) {
    transform: scale(0.935);
    -webkit-transform: scale(0.935);
    transform-origin: 0 0;
    -webkit-transform-origin: 0 0;
  }
  @media (min-width: ${theme.breakpoints.sm}) {
    transform: scale(1);
    -webkit-transform: scale(1);
    transform-origin: 0 0;
    -webkit-transform-origin: 0 0;
  }
`;

interface ReviewProps {
  step: number;
  setStep: (p: number) => void;
  watchTwo: (p: string) => string;
  setIsEditing: (p: boolean) => void;
  setContactDetailsOverride: (p: ContactDetails) => void;
}

export const Review: React.FC<ReviewProps> = ReviewProps => {
  const { setIsEditing, setStep, watchTwo, setContactDetailsOverride } = ReviewProps;
  const isLg = isLgQuery();
  const [reCaptchaResponseValue, setReCaptchaResponseValue] = useState<string>();
  const [setCsrf, { loading: setCsrfLoading }] = useCsrfToken();
  const [sendRegiser, { data: registeredUser, loading: registerLoading }] = useRegister();

  function onReCaptchaResponse(reCaptchaResponse) {
    setReCaptchaResponseValue(reCaptchaResponse);
  }

  useEffect(() => {
    if (!registeredUser?.email) {
      // TODO: XXX: Error logging and messaging
      return;
    }
    setStep(ClaimSteps.VERIFICATION_CODE);
  }, [registeredUser]);

  function handleBack() {
    setIsEditing(true);
    const currentContactDetails: ContactDetails = {
      fullName: watchTwo("fullName"),
      email: watchTwo("email"),
      phoneNumber: watchTwo("phoneNumber"),
    };
    setContactDetailsOverride(currentContactDetails);
    setStep(ClaimSteps.CONTACT_DETAILS);
  }

  const rightBtnOnClickHandler = async () => {
    const req: RegisterRequest = {
      first_name: watchTwo("firstName"),
      last_name: watchTwo("lastName"),
      email: watchTwo("email"),
      phone_number: watchTwo("phoneNumber"),
      recaptcha: reCaptchaResponseValue as string,
    };
    await setCsrf();
    sendRegiser(req);
  };

  return (
    <Content className="row mx-0 justify-content-center px-3 px-lg-0">
      <Wrapper className="styled-content-container zIndex1 mt-1 mb-5">
        <div className="px-3 pt-4 pb-0 rounded-0 modal-body row mx-0">
          <StepHeader className="ps-1 ps-lg-4" title="Contact details" />
          <div
            className={`col-12 mt-2 col-lg-auto ps-1 ps-lg-4 pe-4 pb-1 mb-3 pb-lg-0 mb-lg-0 ${
              isLg && "border-end"
            }`}
          >
            <div className="pe-1">
              <TextLabelValue className="mb-4" label="Full name" value={watchTwo("fullName")} />
              <TextLabelValue className="mb-4" label="Email" value={watchTwo("email")} />
              <TextLabelValue
                className="mb-4"
                label="Phone number"
                value={watchTwo("phoneNumber")}
              />
              {!isLg && (
                <div className="d-flex justify-content-center">
                  <Button
                    ariaLabel="Edit contact details"
                    mode={ButtonModes.open}
                    onClick={handleBack}
                    text="Edit contact details"
                  />
                </div>
              )}
            </div>
          </div>
          <div className={`col px-1 ps-lg-4 row mx-0 align-items-center ${!isLg && "border-top"}`}>
            <StepHeader
              className="px-0 pe-lg-3 col pt-3 mt-4 pt-lg-0 mt-lg-0 mb-45 mb-lg-0"
              noBottomMargin
              noBottomPadding
              title="Get a verification email"
              text={
                <span>
                  We will send a four digit code to <b>{watchTwo("email")}</b> so we can verify your
                  email.
                </span>
              }
            />
            <Col className="px-0" xs="12">
              <ReCAPTCHAContainer>
                <ReCAPTCHA
                  sitekey={config.recaptchaSiteKey as string}
                  onChange={onReCaptchaResponse}
                />
              </ReCAPTCHAContainer>
            </Col>
          </div>
          <CardFormFooter
            className="px-1 px-lg-4 pt-3 mt-1 mt-lg-4 mb-n4 mb-lg-4 pb-3"
            leftBtnOnClick={handleBack}
            leftBtnDisabled={registerLoading || registeredUser?.id !== undefined}
            leftBtnText={isLg ? "Edit contact details" : undefined}
            loading={setCsrfLoading || registerLoading}
            loadingText={"Sending"}
            rightBtnText={"Send verification email"}
            rightBtnDisabled={
              !reCaptchaResponseValue ||
              setCsrfLoading ||
              registerLoading ||
              registeredUser?.id !== undefined
            }
            rightBtnOnClick={rightBtnOnClickHandler}
          />
        </div>
      </Wrapper>
    </Content>
  );
};

export default Review;
