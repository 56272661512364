import { useDateFormatter } from "client/hooks/useDateFormatter";
import { convertToDateIgnoringTime } from "client/utils/dateUtils";
import React, { useContext } from "react";
import { PartnerClaimContext } from "../../../../../context";
import { Heading } from "../../../../../ui-components";
import TextDisplay from "../TextDisplay";
import { StyledImage } from "./styles";

export default function LostItem() {
  const { claim, category } = useContext(PartnerClaimContext);
  const dateFormatter = useDateFormatter();

  return claim === null || category == null ? null : (
    <div className="d-flex flex-column gap-35" data-testid="lostItemSection">
      <Heading variant="h4">Lost item</Heading>

      <div className="row gy-45">
        <div className="col-12" data-testid="itemName">
          <TextDisplay title="Name" content={claim.name} />
        </div>

        <div className="col-12" data-testid="itemCategory">
          <TextDisplay title="Category" content={category.name} />
        </div>

        <div className="col-12" data-testid="itemDateLost">
          <TextDisplay
            title="Date lost"
            content={
              claim?.lost_on ? dateFormatter(convertToDateIgnoringTime(claim.lost_on), "long") : "-"
            }
          />
        </div>

        <div className="col-12">
          <TextDisplay
            title="Description"
            data-testid="itemDescription"
            content={claim.description}
          />
        </div>

        {claim.images.length > 0 && (
          <div className="col-12">
            <TextDisplay title="Photos" />

            <div className="d-flex gap-2 gap-lg-25 flex-wrap">
              {claim.images.map((image, index) => (
                <StyledImage
                  loading="lazy"
                  key={index}
                  src={image.image}
                  className="img-fluid mt-25"
                />
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
