import React from "react";

import { useDateFormatter } from "client/hooks/useDateFormatter";
import { convertToDateIgnoringTime } from "client/utils/dateUtils";
import Badge, { BadgeSizesEnum } from "../../components/Badge";
import { NoPhotoImagePlaceholder } from "../../components/ImageCarousel";
import { TextBold } from "../../components/typography";
import { Category, PartnerClaim } from "../../types";
import { getCategoryName } from "../../utils/mappers";
import { BadgeVariantsEnum } from "../Badge/index";
import TextLabelValue from "../TextLabelValue/TextLabelValue";
import { PhotoCarousel, StyledCarouselItem } from "./styles";

interface MatchedClaimSnapshotProps {
  claim: PartnerClaim;
  categories: Category[];
  className?: string;
  label?: string;
  $showSingleImage?: boolean;
  useLgCarousel?: boolean;
}

const MatchedClaimSnapshot: React.FC<MatchedClaimSnapshotProps> = props => {
  const { claim, categories, className, $showSingleImage } = props;

  const dateFormatter = useDateFormatter();

  return (
    <div className={`${className} d-flex mt-25`}>
      <div className="px-0 col-auto">
        {claim && claim?.images?.length > 0 ? (
          <PhotoCarousel
            touch
            interval={null}
            prevIcon={false}
            nextIcon={false}
            controls={!$showSingleImage && claim?.images?.length > 1}
            $showSingleImage={$showSingleImage}
            indicators={!$showSingleImage && claim?.images?.length > 1}
          >
            <StyledCarouselItem
              key={0}
              style={{ backgroundImage: `url(${claim.images[0]?.image})` }}
            >
              <span role="img" aria-label={`image of ${claim.name}`} />
            </StyledCarouselItem>
          </PhotoCarousel>
        ) : (
          <NoPhotoImagePlaceholder
            className="w-100 d-flex align-items-stretch"
            width={"100px"}
            height={"133px"}
          >
            <span role="img" aria-label={`No image placeholder image`} />
          </NoPhotoImagePlaceholder>
        )}
      </div>
      <div className={`mx-0 w-100 align-items-stretch  align-content-between pe-0 ps-35`}>
        <div className="d-flex flex-column p-0">
          <div className="px-0 d-flex align-items-start mb-25 w-100 justify-content-between">
            {categories.length > 0 && (
              <Badge
                variant={BadgeVariantsEnum.Contained}
                className="text-truncate text-nowrap fw500"
                size={BadgeSizesEnum.Small}
                label={getCategoryName(categories, claim.category)}
              />
            )}
          </div>
          <div className="px-0">
            <TextBold className="pe-35 mb-35">{claim.name}</TextBold>
          </div>
          <TextLabelValue
            label="Date lost"
            value={claim.lost_on && dateFormatter(convertToDateIgnoringTime(claim.lost_on), "long")}
          />
        </div>
      </div>
    </div>
  );
};

export default MatchedClaimSnapshot;
